<div class="w-100"  >
  <div class="row pt-3">
    <div class="col-auto align-middle">
      <visual-object-icon paramType="{{paramDetail.paramType}}"></visual-object-icon>
    </div>
    <div class="col align-middle">{{paramDetail.title}}</div>
    <div class="col text-right align-middle"> <span class="text-right w-100">{{paramDetail.newValue}}</span></div>
    <div class="col-auto align-middle" role="button" (click)="toggleExpand()" style="width:46px;">
      <co-image *ngIf="paramDetail.oldValue" width="16px" height="16px" type="svg" [key]="expanded ? 'collapse' : 'expand'"></co-image>
    </div>
  </div>
  <div class="row" *ngIf="expanded && paramDetail.oldValue">
    <div class="col-auto"></div>
    <div class="col"></div>
    <div class="col text-right old-value-row ">{{paramDetail.oldValue}} </div>
    <div class="col-auto" style="width:46px;"></div>
  </div>
</div>