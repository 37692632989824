import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import * as Immutable from "immutable";
import { ManagedSubscription } from "../../../../shared/managedSubscription";
import { BaseComponent } from "../../../shared";
import { DataSelector } from "../../../shared/models";
import { GridRow } from "../../../shared/models/responses";
import { DataSelectorKeyDetailMessage } from "../../../shared/models/responses/messages";
import { ConfPageSessionService } from "../../providers";
import { DataSelectorStore } from "./dataSelectorStore";
import { DataSelectorParamDetail } from "./uiModels";


@Component({
  selector: 'data-selector-value-detail',
  templateUrl: './dataSelectorValueDetailComponent.html'
})
export class DataSelectorValueDetailComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() dataSelector: DataSelector;
  @Input() selectedValue: GridRow;

  public paramDetails = new Array<DataSelectorParamDetail>();
  public keyDetailSubscription: ManagedSubscription;

  constructor(
    @Inject(DataSelectorStore) public dataSelectorStore: DataSelectorStore,
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService
  ) {
    super();
  }

  ngOnInit() {
    this.keyDetailSubscription = this.dataSelectorStore.onKeyDetailMessageRequest(this.confPageSession.confSessionId, this.dataSelector.visualObjectId, this.selectedValue.id, (messages: Immutable.List<DataSelectorKeyDetailMessage>): void => {
      // Always clear
      this.paramDetails = [];
      messages.get(0).details.forEach((value, key) => {
        this.paramDetails.push(new DataSelectorParamDetail(parseInt(key), value[0], value[1]));
      });
    });
  }

  ngOnDestroy() {
    if (this.keyDetailSubscription)
      this.keyDetailSubscription.unsubscribe();

    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedValue'] && this.selectedValue != null) {
      this.dataSelectorStore.dispatchGetKeyDetail(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId, this.dataSelector.visualObjectId, this.selectedValue.id);
    }
  }
}