import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { HttpService } from "../../shared/providers"
import { AccountDataRequest, ApiResponse, DealerControlPanelRequest } from "../shared/models";
import { ApiResponseBuilder } from "../shared/providers/apiResponseBuilder";

@Injectable()
export class DealerControlPanelController {

  protected dealerControlPanelControllerUrl = "api/dealercontrolpanel";

  constructor(
    @Inject(HttpService) protected httpService: HttpService,
    protected apiResponseBuilder: ApiResponseBuilder
  ) { }

  protected buildResponseObject = map((response, index) => this.apiResponseBuilder.build(response));
  
  public get(requestModel: DealerControlPanelRequest): Observable<ApiResponse> {
    return this.httpService.post(this.dealerControlPanelControllerUrl, requestModel).pipe(this.buildResponseObject);
  }
  
}