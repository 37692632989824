import { Injectable, Inject } from "@angular/core";

import { AppStore, AccountDataActions, AppAsyncAction, HttpAction, ActionStatus, AppAction, RequestStatus } from "../shared/state";
import { AccountDataController } from "../shared/providers/accountData/accountDataController";
import { AccountDataResponse } from "../shared/models/responses";
import { AccountDataRequest, ApiResponse, DealerControlPanelRequest } from "../shared/models";
import { DealerControlPanelController } from "../dealerControlPanel/dealerControlPanelController";

@Injectable()
export class DealerControlPanelActionCreator {

  constructor(
    @Inject(AppStore) protected appStore: AppStore,
    @Inject(DealerControlPanelController) protected dealerControlPanelController: DealerControlPanelController
  ) { }
 
  updateDealerControlPanel(dealerControlPanelRequest: DealerControlPanelRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(
      "Updated successfully",
      (dispatch, state, callback) => {

        return this.dealerControlPanelController.get(dealerControlPanelRequest).subscribe((dealerResponse) => {
          
          dispatch({ type: "Updated successfully", payload: dealerResponse });

          // Update complete request status
          callback(new ActionStatus(true, dealerResponse));
        })
      });
  }

  getRootAssortments(dealerControlPanelRequest: DealerControlPanelRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(
      "Request root assortments",
      (dispatch, state, callback) => {

        return this.dealerControlPanelController.get(dealerControlPanelRequest).subscribe((dealerResponse) => {

          dispatch({ type: "Root assortments fetched", payload: dealerResponse });

          // Update complete request status
          callback(new ActionStatus(true, dealerResponse));
        })
      });
  }
  

  protected createHttpAction<T>(actionType: any, executeAsync: any, payload: T = null): HttpAction<T> {

    let httpAction: HttpAction<T> = {
      type: actionType,
      executeAsync: executeAsync,
      payload: payload
    }

    return httpAction;
  }
}