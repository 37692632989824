import { Component, Inject } from "@angular/core";
import { BaseComponent } from "../../shared";
import { GlobalDataStore } from "../../shared/providers/globalData";
import * as Immutable from "immutable";
import { HttpService, JsonConvert } from "../../../shared/providers";
import { NotificationService } from "../../../shared/components";
import { RouteRedirector } from "../../shared/providers";
import { ConfRouteParams } from "../../configurator/providers";
import { Product } from "../../shared/models";

@Component({
  selector: 'get-started',
  templateUrl: './getStartedComponent.html'
})
export class GetStartedComponent extends BaseComponent {
  public showGetStartedComponent: boolean = false;
  public showHistoryDocument: boolean = false;
  public helpFileUrl: string;
  public historyFileUrl: string;
  public helpDocuments: Immutable.List<string>;
  public historyDocument: string;
  _product: Product;

  constructor(
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(HttpService) public httpService: HttpService
  ) {
    super();
  }

  ngOnInit() {
    this.helpDocuments = this.globalSettings.helpDocumentFilenames;
    this.historyDocument = this.globalSettings.propertyMap.get("historyDocumentFilename");

    if (this.helpDocuments != null && this.helpDocuments.size > 0) {
      this.showGetStartedComponent = true;
      this.helpFileUrl = this.helpDocuments.get(0);
    }
    else {
      this.showGetStartedComponent = false;
    }

    if (this.historyDocument != null) {
      this.showHistoryDocument = true;
      this.historyFileUrl = this.historyDocument;
    }
    else {
      this.showHistoryDocument = false;
    }

  }

  private get globalSettings(): any {
    return this.globalDataStore.getGlobalData().globalSettings;
  }

  public openHelpDocument(event): void {
    if (!this.helpFileUrl)
      return;

    //this.fileExists(this.helpFileUrl).then(resolved => {
    //  if (resolved) 
        window.open(this.helpFileUrl, '_blank');
    //});
  }


  public createQuotation(): void {
    this.createConfiguration(64424509450,'Quotation');
  }

  public createOrder(): void {
    this.createConfiguration(64424509450, 'Order');
  }

  public openHistorytab(): void {
    if (!this.historyFileUrl)
      return;

    //this.fileExists(this.historyFileUrl).then(resolved => {
    //  if (resolved)
        window.open(this.historyFileUrl, '_blank');
    //});
  }

  /**
* Create the configuration
* @param productId
*/
  createConfiguration(prodId: number, type: string) {

    let obj = { type : type};    
    var confParams = JSON.stringify(obj);
    this.routeRedirector.redirectByUrl(`/configurator/editor;productId=${prodId};confParams=${confParams}`);
  }

  // Search for file in folder
  private fileExists(url: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let subscription = this.httpService.get(url+"?getmetadata=true", { observe: 'response' }).subscribe((response) => {
        resolve(response.status == 200);
        subscription.unsubscribe();
      }, (error) => {
        subscription.unsubscribe();
        return resolve(false);
      });
    });
  }

}