<backdrop-container>
  <regular-popup *ngIf="visible"
                 [id]="id"
                 [title]="title"
                 [width]="width"
                 [height]="height"
                 minHeight="300px"
                 [showHeader]="true"
                 [showFooter]="true"
                 [closeOnOutSideClick]="false"
                 [okButtonText]="'Accept'"
                 [cancelButtonText]="'Decline'"
                 (okClick)="onOkClick($event)"
                 (cancelClick)="onCancelClick($event)"
                 [cancelButton]="true">

    <div card-type="header">
      <div class="row py-1 m-0">
        <co-image class="col-auto px-0 align-middle" width="48px" height="48px" type="svg" imageSet="primary-dark" key="{{icon}}"></co-image>
        <div class="col modal-text-right">
          <p style="font-weight:bold">{{title}}</p>
          <p class="pt-2">{{description}}</p>
        </div>
      </div>
    </div>
    <div *ngIf="message" card-type="body" class="p-4">
      <p>{{message}}</p>
    </div>
  </regular-popup>
</backdrop-container>