import { ParamType } from "../../../shared/components/shared";

export class DataSelectorParamDetail {

  public paramType: ParamType;

  public title: string;

  constructor(
    /** Parameter ID or MultiChoiceValueID for multichoices. */
    public keyId: number,
    public oldValue: string,
    public newValue: string,
  ) { }
}