<div class="detail d-flex container">
  <co-image width="48px" height="48px" type="svg" key="info"></co-image>
  <div class="w-100 pl-3">
    <h4 class="pb-2">{{strings.getString('SelectedValueDetail')}}</h4>
    <div class="py-2" *ngIf="paramDetails.length < 1; else showParamDetails">{{format(strings.getString('PleaseConfirmTheSelectionOfX'), selectedValue.name)}}</div>
    <ng-template #showParamDetails>
      <div class="py-2 dataselector-value-detail-header">{{strings.getString('ThisWillAlsoSetTheValuesBelow')}}</div>
      <data-selector-param-detail class="data-selector-value-detail" *ngFor="let paramDetail of paramDetails" [paramDetail]="paramDetail">
      </data-selector-param-detail>
    </ng-template>
  </div>
</div>