import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { AppStore, BaseStore, HttpAction, ActionInfo, RequestStatus, StoreResponse } from "../shared/state";
import { DataSelectorGridMessage, DataSelectorKeyDetailMessage, DataSelectorOperationMessage } from "../shared/models/responses/messages";
import { DataSelectorActionCreator, } from "../configurator/decorations/dataSelector/dataSelectorActionCreator";
import { DataSelectorController } from "..//configurator/decorations/dataSelector/dataSelectorController";
import { ConfMessageProvider, ConfigurationSessionManager, ConfiguratorStore, ConfPageSessionService } from "../configurator/providers";
import { FilterType } from "../../shared/components/shared";
import { ManagedSubscription, SubscriptionOptions, IObserverOptions, ICallback } from "../../shared/managedSubscription";
import { AppStoreSubscriptionManager, ModelFactory } from "../shared/providers";
import { ConfDataRequest, DataSelectorCommand, SearchArgument, GridArgument, PaginationArgument, DealerControlPanelMessage, DealerControlPanelRequest, ApiResponse, DealerControlPanelResponse, AuthorizationGroup } from "../shared/models";
import { GlobalDataStore } from "../shared/providers/globalData";
import { DealerControlPanelActionCreator } from "./dealerControlPanelActionCreator";
import { ManagedSubject } from "../../shared/managedSubject";
import { ReselectorService } from "../shared/providers/reselectorService";
import { AccountDataStore } from "../shared/providers/accountData";

@Injectable()
export class DealerControlPanelStore extends BaseStore {

  constructor(
    @Inject(AppStore) protected appStore: AppStore,
    @Inject(DealerControlPanelActionCreator) protected actionCreator: DealerControlPanelActionCreator,
    @Inject(AppStoreSubscriptionManager) protected appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ModelFactory) protected modelFactory: ModelFactory,
    protected reselectorService: ReselectorService,
    protected globalDataStore: GlobalDataStore
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory);
  }

  public createRequest(rawObject?: Partial<ConfDataRequest>): ConfDataRequest {
    return this.modelFactory.createRequestOrCommand<ConfDataRequest>(ConfDataRequest.name, rawObject);
  }

  public getRootAssortments(dealerControlPanelCommand: DealerControlPanelRequest): ManagedSubject<StoreResponse<DealerControlPanelResponse>> {
    let action = this.appStore.dispatch(this.actionCreator.getRootAssortments(dealerControlPanelCommand)) as HttpAction<ApiResponse>;

    let response: StoreResponse<DealerControlPanelResponse> = new StoreResponse<DealerControlPanelResponse>();

    return this.createAction(action, actionInfo => {
      let dealerDataResponse = actionInfo.payload.data as DealerControlPanelResponse;
      response.data = dealerDataResponse;
      return response;
    });
  }

  public storeObjects(dealerControlPanelCommand: DealerControlPanelRequest): ManagedSubject<StoreResponse<DealerControlPanelResponse>> {
    let action = this.appStore.dispatch(this.actionCreator.updateDealerControlPanel(dealerControlPanelCommand)) as HttpAction<ApiResponse>;
 
    let response: StoreResponse<DealerControlPanelResponse> = new StoreResponse<DealerControlPanelResponse>();

    return this.createAction(action, actionInfo => {
      let dealerDataResponse = actionInfo.payload.data as DealerControlPanelResponse;
      response.data = dealerDataResponse;
      return response;
    });
  }
  
  protected createStoreSubscription<T>(identifier: string, memorizer: () => T, subscriptionOptions: SubscriptionOptions<T>): ManagedSubscription {

    let managedSubject: ManagedSubject<T> = this.appStoreSubscriptionManager.getOrCreateStoreSubject<T>(identifier, memorizer, false, 1, false);
    return managedSubject.subscribe(subscriptionOptions);
  }

  public onActionCompleted(httpAction: HttpAction<any>, onComplete?: (actionInfo: ActionInfo) => void, onFailure?: (actionInfo: ActionInfo) => void) {

    this.createAction(httpAction, onComplete, onFailure);

  }  
  
}