import { PriceCategory, CusExchangeRateOverride, Currency } from "../shared/models";
import { InputViewModel } from "../../shared/components";

export class ExchangeRateOverrideModel {
  public currency: Currency;
  public exchangeRateOverride: CusExchangeRateOverride;
  public overrideView: InputViewModel;
  public validUntilView: InputViewModel;  
}

