<div id="dealer-control-panel" class="page-margin-top page-margin-bottom page-margin-left">
  <gridview classes="pr-0">
    <gridview-row classes="pb-2">
      <columntemplate size="auto">
        <dropdown [view]="workgroupView"
                  [itemsView]="workgroupsView"
                  (onValueChange)="workGroupChanged()">
        </dropdown>
      </columntemplate>
      <columntemplate size="auto">
        <dropdown *ngIf="productView != null" [view]="productView"
                  [itemsView]="productsView"
                  (onValueChange)="productChanged()">
        </dropdown>

      </columntemplate>
    </gridview-row>
    <gridview-row id="price-grid" classes="">
      <columntemplate classes="">
        <gridview classes="">
          <gridview-header>
            <header-columntemplate classes="header-title">
              {{strings.ProductFamily}}
            </header-columntemplate>
          </gridview-header>
          <gridview-row classes="" *ngIf="dealerPriceCategoryModels && dealerPriceCategoryModels.length">
            <columntemplate classes="">
              <gridview classes="overflow-auto pl-0">
                <gridview-row classes="font-weight-bold" [style.min-width.px]="priceGridWidth" [style.min-height.px]="48">
                  <columntemplate classes="empty-content price-category">
                  </columntemplate>
                  <columntemplate classes="invisible help-width">
                    Help
                  </columntemplate>
                  <columntemplate classes="invisible price-type">
                    Type
                  </columntemplate>
                  <columntemplate classes="price-width px-2" *ngFor="let row1 of headerRow1">
                    {{row1}}
                  </columntemplate>
                </gridview-row>
                <gridview-row classes="label-color pb-3" [style.min-width.px]="priceGridWidth">
                  <columntemplate classes="price-category">
                    {{strings.Category}}
                  </columntemplate>
                  <columntemplate classes="px-1 help-width">
                    <div role="button" class="input-info no-border">
                      <co-image (click)="helpClick($event)"
                                [width]="'32'"
                                [height]="'32'"
                                type="svg"
                                key="Info"></co-image>
                    </div>
                  </columntemplate>
                  <columntemplate size="auto" classes="price-type">
                    Type
                  </columntemplate>
                  <columntemplate classes="price-width" *ngFor="let row2 of headerRow1">
                    <gridview-row classes="price-factor mx-0">
                      <columntemplate classes="px-1">
                        <p title="Retail Markup">RM</p>
                      </columntemplate>
                      <columntemplate classes="px-1" *ngIf="wmViewVisible">
                        <p title="Wholesaler Markup">WM</p>
                      </columntemplate>
                    </gridview-row>
                  </columntemplate>
                </gridview-row>
                <gridview-row classes="" *ngFor="let priceModel of dealerPriceCategoryModels" [style.min-width.px]="priceGridWidth">
                  <columntemplate classes="price-category">
                    <p title="{{priceModel.priceCategory.propertyMap.get('cusHelpText')}}">{{priceModel.priceCategory.name}}</p>
                  </columntemplate>
                  <columntemplate classes="help-width">&nbsp;</columntemplate>
                  <columntemplate classes="price-type px-2">
                    <dropdown [view]="priceModel.typeView"
                              [itemsView]="priceModel.typeItemsView">
                    </dropdown>
                  </columntemplate>
                  <columntemplate classes="price-width" *ngFor="let dealerProductPriceFactorModel of priceModel.dealerProductPriceFactorModels">
                    <gridview-row classes="price-factor mx-0">
                      <columntemplate classes="px-1">
                        <text-box *ngIf="dealerProductPriceFactorModel.rmView" [view]="dealerProductPriceFactorModel.rmView" type="number" [stepValue]="0.1"></text-box>
                      </columntemplate>
                      <columntemplate classes="px-1" *ngIf="wmViewVisible">
                        <text-box *ngIf="dealerProductPriceFactorModel.wmView" [view]="dealerProductPriceFactorModel.wmView" type="number" [stepValue]="0.1"></text-box>
                      </columntemplate>
                      <!--<columntemplate size="auto" classes="px-1">
                        <text-box *ngIf="dealerProductPriceFactorModel.markupView" [view]="dealerProductPriceFactorModel.markupView" type="number"></text-box>
                      </columntemplate>-->
                    </gridview-row>
                  </columntemplate>
                </gridview-row>
              </gridview>
            </columntemplate>
          </gridview-row>
          <gridview-row>
            <columntemplate classes="py-2">
              <button class="btn btn-primary" (click)="savePriceFactors()">{{strings.getString('savepricefactors')}}</button>
            </columntemplate>
          </gridview-row>
        </gridview>
      </columntemplate>
    </gridview-row>
    <gridview-row classes="">
      <columntemplate classes="">
        <gridview id="exchange-grid" classes="pr-0">
          <gridview-header>
            <header-columntemplate classes="header-title">
              {{strings.getString('exchangerateoverride')}}
            </header-columntemplate>
          </gridview-header>
          <div class="overflow-auto">
            <gridview-row classes="label-color pb-3 exchange-row">
              <columntemplate classes="currency">
                {{strings.Currency}}
              </columntemplate>
              <columntemplate classes="exchange-col">
                {{strings.getString('system')}}
              </columntemplate>
              <columntemplate classes="exchange-col px-1">
                {{strings.getString('override')}}
              </columntemplate>
              <columntemplate classes="exchange-col px-1">
                {{strings.getString('validuntil')}}
              </columntemplate>
            </gridview-row>
            <gridview-row classes="py-1 exchange-row" *ngFor="let exchangeRateOverrideModel of exchangeRateOverrideModels">
              <columntemplate classes="currency">
                {{exchangeRateOverrideModel.currency.identity}}
              </columntemplate>
              <columntemplate classes="exchange-col">
                {{exchangeRateOverrideModel?.currency?.factor?.toPrecision(7)}}
              </columntemplate>
              <columntemplate classes="px-1 exchange-col">
                <text-box [view]="exchangeRateOverrideModel.overrideView" type="number"></text-box>
              </columntemplate>
              <columntemplate classes="px-1 exchange-col">
                <text-box [view]="exchangeRateOverrideModel.validUntilView" type="date"></text-box>
              </columntemplate>
            </gridview-row>
          </div>
          <gridview-row>
            <columntemplate classes="py-2">
              <button class="btn btn-primary" (click)="saveExchangeRates()">{{strings.getString('saveoverrides')}}</button>
            </columntemplate>
          </gridview-row>
        </gridview>
      </columntemplate>
    </gridview-row>
    <gridview-row id="others-grid" classes="">
      <columntemplate classes="">
        <gridview classes="">
          <gridview-header>
            <header-columntemplate classes="header-title">
              {{strings.getString('quotetexts')}}
            </header-columntemplate>
          </gridview-header>
          <gridview-row>
            <columntemplate size="auto" classes="px-1 pl-3">
              <text-box [view]="defaultDaysView" type="number"></text-box>
            </columntemplate>
          </gridview-row>
          <gridview-row>
            <columntemplate size="4" classes="px-1 textarea-min-width pl-3">
              <text-box [view]="introTextView" type="text" [rows]="6"></text-box>
            </columntemplate>
            <columntemplate size="4" classes="px-1 textarea-min-width ">
              <text-box [view]="termsTextView" type="text" [rows]="6"></text-box>
            </columntemplate>
          </gridview-row>
          <gridview-row>
            <columntemplate size="auto" classes="px-1 pl-3">
              <text-box [view]="companyNameView" type="text"></text-box>
            </columntemplate>
          </gridview-row>
          <gridview-row>
            <columntemplate size="auto" classes="px-1pl-3">
              <text-box [view]="adressLine1View" type="text"></text-box>
            </columntemplate>
            <columntemplate size="auto" classes="px-1">
              <text-box [view]="adressLine2View" type="text"></text-box>
            </columntemplate>
          </gridview-row>
          <gridview-row>
            <columntemplate size="auto" classes="px-1pl-3">
              <text-box [view]="postalCodeView" type="text"></text-box>
            </columntemplate>
            <columntemplate size="auto" classes="px-1">
              <text-box [view]="cityView" type="text"></text-box>
            </columntemplate>
          </gridview-row>
          <gridview-row>
            <columntemplate size="auto" classes="px-1pl-3">
              <text-box [view]="countryView" type="text"></text-box>
            </columntemplate>
            <columntemplate size="auto" classes="px-1">
              <text-box [view]="phoneView" type="text"></text-box>
            </columntemplate>
          </gridview-row>
          <gridview-row>
            <columntemplate size="auto" classes="px-1pl-3">
              <text-box [view]="mailView" type="text"></text-box>
            </columntemplate>
            <columntemplate size="auto" classes="px-1">
              <text-box [view]="webView" type="text"></text-box>
            </columntemplate>
          </gridview-row>
          <gridview-row>
            <columntemplate size="auto" classes="px-1pl-3">
              <text-box [view]="vatView" type="text"></text-box>
            </columntemplate>
          </gridview-row>
          <gridview-row>
            <columntemplate classes="py-2">
              <button class="btn btn-primary" (click)="saveOthers()">{{strings.getString('savetexts')}}</button>
            </columntemplate>
          </gridview-row>
        </gridview>
      </columntemplate>
    </gridview-row>
  </gridview>
</div>
<dealer-help-popup></dealer-help-popup>