<backdrop-container>
  <regular-popup *ngIf="visible"
                 title="{{strings.ConfirmDataSelectAction}}"
                 [showHeader]="false"
                 [showFooter]="true"
                 width="750px"
                 height="380px"
                 minHeight="300px"
                 [okButtonText]="strings.Ok"
                 [cancelButtonText]="strings.Cancel"
                 (okClick)="onOkClicked()"
                 (onClosed)="onPopupClosed()">
    <div card-type="body" class="p-4">
      <data-selector-value-detail *ngIf="selectedValue != null"
                                  [selectedValue]="selectedValue"
                                  [dataSelector]="dataSelector">
      </data-selector-value-detail>
    </div>
  </regular-popup>
</backdrop-container>