import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";
import { BomColumnSetting } from "../../../../pages/shared/models/responses/bomColumnSetting";
import { ConfDataProperty } from "../../../../pages/shared/models/responses/confDataProperty";
import { GlobalSettings } from "../../../../pages/shared/models/responses/globalSettings";

export class DoosanGlobalSettings extends GlobalSettings {
  
  protected readonly DEALER_CONTROL_PANEL_AUTHORIZATION_GROUP_ID = "dealerControlPanelAuthorizationGroupId";
  protected readonly DISPLAY_TERMS_OF_USE_POPUP = "displayTermsOfUsePopup";
  protected readonly HISTORY_DOCUMENT_FILENAME = "historyDocumentFilename";
  protected readonly WON_STATE_ID = "wonStateId";

  get dealerControlPanelAuthorizationGroupId(): number { return this.getInternalValue<number>(this.DEALER_CONTROL_PANEL_AUTHORIZATION_GROUP_ID); }
  setDealerControlPanelAuthorizationGroupId(dealerControlPanelAuthorizationGroupId: number): this { return this.setInternalValue(this.DEALER_CONTROL_PANEL_AUTHORIZATION_GROUP_ID, dealerControlPanelAuthorizationGroupId); }  

  get displayTermsOfUsePopup(): boolean { return this.getInternalValue<boolean>(this.DISPLAY_TERMS_OF_USE_POPUP); }
  setDisplayTermsOfUsePopup(displayTermsOfUsePopup: boolean): this { return this.setInternalValue(this.DISPLAY_TERMS_OF_USE_POPUP, displayTermsOfUsePopup); }

  get historyDocumentFilename(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.HISTORY_DOCUMENT_FILENAME); }
  setHistoryDocumentFilename(historyDocumentFilename: Immutable.List<string>): this { return this.setInternalValue(this.HISTORY_DOCUMENT_FILENAME, historyDocumentFilename); }

  get wonStateId(): number { return this.getInternalValue<number>(this.WON_STATE_ID); }
  setWonStateId(wonStateId: number): this { return this.setInternalValue(this.WON_STATE_ID, wonStateId); }

}