import { Component, Input, OnInit } from "@angular/core";
import { BaseComponent } from "../../../shared";
import { ParamType } from "../../../shared/components/shared";
import { MultiChoiceParam, MultiChoiceValue, Param } from "../../../shared/models";
import { ProductDataStore } from "../../../shared/providers/productData";
import { DataSelectorParamDetail } from "./uiModels";


@Component({
  selector: 'data-selector-param-detail',
  templateUrl: './dataSelectorParamDetailComponent.html'
})
export class DataSelectorParamDetailComponent extends BaseComponent implements OnInit {

  @Input() paramDetail: DataSelectorParamDetail;

  public expanded = false;

  constructor(
    public productDataStore: ProductDataStore,
  ) {
    super();
  }

  ngOnInit() {
    // Parameter or MultiChoiceValue for multichoices.
    let baseModel = this.productDataStore.getEntity(this.paramDetail.keyId);

    if (baseModel instanceof MultiChoiceValue) {
      let multiChoiceValue = baseModel as MultiChoiceValue;
      let param = this.productDataStore.getEntity(multiChoiceValue.parameterId) as MultiChoiceParam;
      this.paramDetail.paramType = this.getParamType(param);
      this.paramDetail.title = `${param.title}, ${multiChoiceValue.title}`;
    }
    else {
      let param = baseModel as Param;
      this.paramDetail.paramType = this.getParamType(param);
      this.paramDetail.title = param.title;
    }
  }

  public getParamType(param: Param) {
    return ParamType[param.className];
  }

  public toggleExpand() {
    if (this.paramDetail.oldValue) {
      this.expanded = !this.expanded;
    }
  }
}