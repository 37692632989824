import { PriceCategory } from "../shared/models";
import { DealerProductPriceFactorModel } from "./dealerProductPriceFactorModel";
import { InputViewModel } from "../../shared/components";

export class DealerPriceCategoryModel {
  public priceCategory: PriceCategory;
  public dealerProductPriceFactorModels : Array<DealerProductPriceFactorModel> = [];
  public typeView: InputViewModel;
  public typeItemsView: InputViewModel[] = [];
}

