import { ChangeDetectorRef, Component, ViewEncapsulation, Inject } from "@angular/core";
import { Routing } from "../../shared/route/routeDecorator";
import { AppStoreSubscriptionManager, ApplicationLifeCycleService, ApiMessageProvider, AppActionCreator, ModelFactory } from "../shared/providers";
import { PageComponent } from "../shared";
import { GlobalDataStore } from "../shared/providers/globalData";
import { AccountDataStore } from "../shared/providers/accountData";
import { NotificationService, NotificationInfo, NotificationType, InputViewModel, PopupService } from "../../shared/components";
import { CacheReloadMessage, Product, ProductFamily, Assortment, PriceCategory, Currency, CusExchangeRateOverride, CusPriceFactor, WorkGroup, DealerControlPanelRequest, WorkGroupArgument, PriceFactorArgument, ExchangeRateOverridesArgument, User, DealerControlPanelResponse, AssortmentCommand, ParamValue, Param } from "../shared/models";
import { StoreResponse } from "../shared/state/storeResponse";
import { ProductDataStore } from "../shared/providers/productData";
import { BaseEntity } from "../shared/baseEntity";
import { DealerPriceCategoryModel } from "./dealerPriceCategoryModel";
import { DealerProductPriceFactorModel } from "./dealerProductPriceFactorModel";
import { ExchangeRateOverrideModel } from "./exchangeRateOverrideModel";
import { DealerControlPanelStore } from "./dealerControlPanelStore";
import { DealerControlPanelActionCreator } from "./dealerControlPanelActionCreator";
import { DealerControlPanelController } from "./dealerControlPanelController";
import { ActionStatus, RequestStatus } from "../shared/state";
import { DealerAuthGuard } from "./dealerAuthGuard";
import { ManagedSubject } from "../../shared/managedSubject";

@Routing({ path: "./dealerControlPanel" })
@Routing({ path: "**" })
@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./dealerControlPanelComponent.html",
  providers: [AppStoreSubscriptionManager, DealerControlPanelStore, DealerControlPanelActionCreator, DealerControlPanelController, DealerAuthGuard]
})
export class DealerControlPanelComponent extends PageComponent {
  public priceGridWidth: number;
  public wmViewVisible: boolean;
  public user: User;
  public workgroupView: InputViewModel;
  public workgroupsView: Array<InputViewModel> = [];
  public productView: InputViewModel;
  public productsView: Array<InputViewModel> = [];
  public productsByFamilyId: Map<number, Array<Product>>;
  public productsByGrouping: Map<string, Array<Product>>;
  public productFamilyPathByFamilyId: Map<number, string>;
  public workGroups: Array<WorkGroup>;
  public priceCategories: Array<PriceCategory>;
  public cusPriceFactors: Array<CusPriceFactor>;
  public currencies: Array<Currency>;
  public headerRow1: Array<string> = [];
  public cusExchangeRateOverrides: Array<CusExchangeRateOverride>;
  public dealerPriceCategoryModels: Array<DealerPriceCategoryModel>;
  public exchangeRateOverrideModels: Array<ExchangeRateOverrideModel>;
  public defaultDaysView: InputViewModel;
  public introTextView: InputViewModel;
  public termsTextView: InputViewModel;
  public companyNameView: InputViewModel;
  public adressLine1View: InputViewModel;
  public adressLine2View: InputViewModel;
  public postalCodeView: InputViewModel;
  public countryView: InputViewModel;
  public phoneView: InputViewModel;
  public mailView: InputViewModel;
  public webView: InputViewModel;
  public cityView: InputViewModel;
  public vatView: InputViewModel;

  serverResponse: any;
  
  constructor(
    public appActionCreator: AppActionCreator,
    public appLifeCycleService: ApplicationLifeCycleService,
    public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    public globalDataStore: GlobalDataStore,
    public accountStore: AccountDataStore,
    public productStore: ProductDataStore,
    public modelFactory: ModelFactory,
    public dealerControlPanelStore: DealerControlPanelStore,
    public notificationService: NotificationService,
    public popupService: PopupService,
    public cdr: ChangeDetectorRef,
  ) {
    super();
  }




  ngOnInit() {

    // Save product "default". 
    // Update objects in store after save
    // Lyssna på dealercontrolpanelmessage, visa message success och populera igen (notifyservice)
    // Logotype - visa även den som finns
    // [CUS_PRICE_FACTOR] save skall få med modification date osv

    this.user = this.accountStore.getUser();
    this.productsByFamilyId = new Map<number, Array<Product>>();
    this.productFamilyPathByFamilyId = new Map<number, string>();

    // Create products drop down
    this.productView = new InputViewModel(this.strings.ProductFamily);
    this.productsView = [];
    this.productView.icon = "product";
    this.productView.width = "500px";
    this.productsView.push(new InputViewModel());

    // Get all data before populating.
    this.accountStore.getEntities<WorkGroup>(WorkGroup.name).subscribe(response => {
      this.workGroups = response.data.toArray().sort((left, right): number => {
        if (left.title > right.title) return 1;
        if (left.title < right.title) return -1;
        return 0;
      });
      
      this.InitializeWorkGroupInput();

      let dealerControlPanelRequest = this.modelFactory.createRequestOrCommand<DealerControlPanelRequest>(DealerControlPanelRequest.name);
      dealerControlPanelRequest.getRootAssortments = true;

      //this.productStore.getRootAssortments().subscribe(request => {
      this.dealerControlPanelStore.getRootAssortments(dealerControlPanelRequest).subscribe(response => {
        this.serverResponse = response;

        this.populateProductFamily();
      
        this.globalDataStore.getEntities<PriceCategory>(PriceCategory.name).subscribe(response => {
          this.priceCategories = response.data.toArray().sort((left, right): number => {
            if (left.name > right.name) return 1;
            if (left.name < right.name) return -1;
            return 0;
          });

          this.globalDataStore.getEntities<CusPriceFactor>(CusPriceFactor.name).subscribe(response => {
            this.cusPriceFactors = response.data.toArray();

            // Populate models for price factors and other (workGroup).
            this.populatePriceFactors();
            this.populateOthers();
            this.cdr.detectChanges();

          }).unsubscribeOn(this.unsubscribeSubject);
        }).unsubscribeOn(this.unsubscribeSubject);
      }).unsubscribeOn(this.unsubscribeSubject);

      this.globalDataStore.getEntities<Currency>(Currency.name).subscribe(response => {
        this.currencies = response.data.toArray().sort((left, right): number => {
          if (left.identity > right.identity) return 1;
          if (left.identity < right.identity) return -1;
          return 0;
        });

        this.globalDataStore.getEntities<CusExchangeRateOverride>(CusExchangeRateOverride.name).subscribe(response => {
          this.cusExchangeRateOverrides = response.data.toArray();
          this.populateExchangeRates();
          this.cdr.detectChanges();

        }).unsubscribeOn(this.unsubscribeSubject);
      }).unsubscribeOn(this.unsubscribeSubject);
    }).unsubscribeOn(this.unsubscribeSubject);

  }

  public InitializeWorkGroupInput() {
    this.workgroupView = new InputViewModel(this.strings.WorkGroup);
    this.workgroupView.icon = "user";
    this.workgroupView.width = "447px";
    this.workgroupsView = [];

    // Get authorized workgroups and populate drop down

    // Add users work group first
    //let user = this.accountStore.getAccountData().user;
    let workGroupObj = this.user.workGroup;
    let viewModel = new InputViewModel(workGroupObj.text);
    viewModel.id = workGroupObj.longId.toString();
    this.workgroupsView.push(viewModel);

    // Choose first work group in list
    this.workgroupView.value = viewModel.id;
    this.workgroupView.formattedValue = viewModel.title;
    
    if (this.workGroups.length > 1) {
      // Responible for more work groups? Add those and ALL to drop down

      // Add separator
      let separatorModel = new InputViewModel("─".repeat(24));
      separatorModel.disabled = true;
      this.workgroupsView.push(separatorModel);
      // Add 'All' work group
      let allModel = new InputViewModel("All");
      allModel.id = "-1";
      this.workgroupsView.push(allModel);
      // Add separator
      this.workgroupsView.push(separatorModel);

      // Add all authorized work groups
      this.workGroups.forEach(workGroup => {
        if (workGroupObj.longId != workGroup.longId) {
          let viewModel = new InputViewModel(workGroup.title);
          viewModel.id = workGroup.longId.toString();
          this.workgroupsView.push(viewModel);
        }
      });
    }
    else {
      // Only one work group, set it as read only
      viewModel.readOnly = true;
    }

    this.updateVisibility();
  }

  private updateVisibility() {
    let workGroup = this.workGroups.find(workGroup => workGroup.longId === this.user.workGroup.longId);
    this.wmViewVisible = false;
    if (workGroup)
      this.wmViewVisible = workGroup.cusResellerType === 1; // 1 = Direct seller (show WM), 0 = Indirect (hide WM)


    let selectedWorkGroup = this.workGroups.find(workGroup => workGroup.longId === parseInt(this.workgroupView.value));
    if (selectedWorkGroup)
      this.wmViewVisible = this.wmViewVisible && selectedWorkGroup != workGroup;
    else
      this.wmViewVisible = false;
  }

  public workGroupChanged() {

    this.updateVisibility();
    this.populateProductFamily();
    this.populatePriceFactors();
    this.populateExchangeRates();
    this.populateOthers();
    this.cdr.detectChanges();
  }

  public productChanged() {
    this.populatePriceFactors();
    this.cdr.detectChanges();
  }
 
  public populateProductsByFamilyId(parentId: number, assortment: Assortment, familyName: string) {
    if (assortment instanceof ProductFamily) {
      this.getAssortments(assortment).subscribe(children => {
        let path = familyName ? (familyName + "\\" + assortment.title) : assortment.title;
        children.data.forEach((child: Assortment) => {
          this.populateProductsByFamilyId(assortment.longId, child, path);
        });
      }).unsubscribeOn(this.unsubscribeSubject);
    }
    else {
      if (!this.productsByFamilyId.has(parentId)) {
        this.productsByFamilyId.set(parentId, []);
        if (familyName) // Skip ROOT level
        {
          this.productFamilyPathByFamilyId.set(parentId, familyName);
          let familyModel = new InputViewModel(familyName);
          familyModel.id = parentId.toString();
          this.productsView.push(familyModel);
        }
      }
      this.productsByFamilyId.get(parentId).push(assortment as Product);
    }
  }
  // This method is depending on the order coming from server, See DealerControlPanelRequestHandler.cs - GetQuotationProducts()
  public populateProductFamily() {
    let marketId: number = 0;
    let workGroup = this.workGroups.find(workGroup => workGroup.longId === parseInt(this.workgroupView.value));
    if (workGroup) {
      marketId = workGroup.cusMarket;
    }
    let grouping = "----";
    this.productsByGrouping = new Map<string, Array<Product>>();

    this.productsView = [];

    let selectionIsValid: boolean = false;

    this.serverResponse.data.products.forEach((assortment: Assortment) => {
      let include: boolean = true;
      let product = assortment as Product;
      if (marketId > 0 && product.cusHideForMarkets > "") {
        if (product.cusHideForMarkets.includes(marketId.toString())) {
          include = false;
        }
      }
      if (include) {

        if (product.cusGrouping != grouping) {
          grouping = product.cusGrouping;
          let familyModel = new InputViewModel(grouping);
          familyModel.id = grouping;
          this.productsView.push(familyModel);

          if (this.productView.value == familyModel.id)
            selectionIsValid = true;

          let productsList: Product[] = [];
          this.productsByGrouping.set(grouping, productsList);
        }
        this.productsByGrouping.get(product.cusGrouping).push(product);
      }
    });

    // Clear selection if not available anymore
    if (!selectionIsValid) {
      this.productView = new InputViewModel(this.strings.ProductFamily);
      this.productView.icon = "product";
      this.productView.width = "500px";
    }   
  }

  public populatePriceFactors() {
    // Clear old models
    this.dealerPriceCategoryModels = [];

    let products = this.productsByGrouping.get((this.productView.value)); 
    if (!this.priceCategories) {
      return;
    }

    let allWorkGroups = this.workgroupView.value === "-1";

    let allWorkGroupsButTheUsers: Array<WorkGroup> = [];
    let workGroupIds: Array<number> = [];
    if (allWorkGroups) {
      // Remove user workGroup from list
      allWorkGroupsButTheUsers = this.workGroups.filter(workGroup => workGroup.longId != this.user.workGroup.longId);
      allWorkGroupsButTheUsers.forEach(workGroup => {
        workGroupIds.push(workGroup.longId);
      });
    }

    // Header row with product names, and Default.
    this.headerRow1 = [];
    this.headerRow1.push("Default");
    if (products) {
      products.forEach(product => {
        this.headerRow1.push(product.title);
      });
    }

    // Calculate grid width, every product takes 145px and category and type takes 315px.
    this.priceGridWidth = (this.headerRow1.length * 145) + 315;

    // Create all dealerPriceCategoryModels and set values from db.
    this.priceCategories.forEach(priceCategory => {
      let dealerPriceCategoryModel = new DealerPriceCategoryModel();
      dealerPriceCategoryModel.priceCategory = priceCategory;
      dealerPriceCategoryModel.typeView = new InputViewModel();
      dealerPriceCategoryModel.typeView.width = "35px";
      dealerPriceCategoryModel.typeView.readOnly = !this.wmViewVisible;
      dealerPriceCategoryModel.typeItemsView = [];
      let items = ["%", "€"];
      items.forEach((item, index) => {
        let itemModel = new InputViewModel(item);
        itemModel.id = index.toString();
        dealerPriceCategoryModel.typeItemsView.push(itemModel);
      });

      // Default product
      let priceFactors: Array<CusPriceFactor> = [];
      let defaultPriceFactor: CusPriceFactor = null;
      if (!allWorkGroups) {
        priceFactors = this.cusPriceFactors.filter((priceFactor: CusPriceFactor) => priceFactor.priceCategoryId === priceCategory.longId && priceFactor.workgroupId === parseInt(this.workgroupView.value)) as CusPriceFactor[] || [];

        // Try to find default pricefactor for this workgroup. Id -1 = 64424509439 as longId;
        defaultPriceFactor = priceFactors.find(priceFactor => priceFactor.productId === 64424509439) || this.modelFactory.createAny(CusPriceFactor.name);
      }
      else {
        // default for allWorkGroups. Id -1 = 64424509439 as longId;
        let possibleMatchingPriceFactors = this.cusPriceFactors.filter(x => x.priceCategoryId === priceCategory.longId && x.productId === 64424509439 && workGroupIds.indexOf(x.workgroupId) > -1);

        defaultPriceFactor = this.modelFactory.createAny(CusPriceFactor.name);
        defaultPriceFactor = defaultPriceFactor.setProductId(-1);
        defaultPriceFactor = defaultPriceFactor.setWorkgroupId(-1);

        if (workGroupIds.length == possibleMatchingPriceFactors.length) {
          // We have one cusPriceFactors for each work group. If they have the same value, show it.
          if (possibleMatchingPriceFactors.every(x => x.markupType == possibleMatchingPriceFactors[0].markupType)) {
            defaultPriceFactor = defaultPriceFactor.setMarkupType(possibleMatchingPriceFactors[0].markupType);
          }
          if (possibleMatchingPriceFactors.every(x => x.wholesalerMarkup == possibleMatchingPriceFactors[0].wholesalerMarkup)) {
            defaultPriceFactor = defaultPriceFactor.setWholesalerMarkup(possibleMatchingPriceFactors[0].wholesalerMarkup);
          }
          if (possibleMatchingPriceFactors.every(x => x.retailMarkup == possibleMatchingPriceFactors[0].retailMarkup)) {
            defaultPriceFactor = defaultPriceFactor.setRetailMarkup(possibleMatchingPriceFactors[0].retailMarkup);
          }
        }
      }

      if (defaultPriceFactor.markupType) {
        dealerPriceCategoryModel.typeView.id = defaultPriceFactor.markupType.toString();
        dealerPriceCategoryModel.typeView.value = items[defaultPriceFactor.markupType];
      }

      // Create model for default price factor
      let dealerProductPriceFactorModel = new DealerProductPriceFactorModel();
      dealerProductPriceFactorModel.priceFactor = defaultPriceFactor;
      dealerProductPriceFactorModel.rmView = new InputViewModel();
      dealerProductPriceFactorModel.rmView.width = "60px";
      dealerProductPriceFactorModel.rmView.value = defaultPriceFactor.retailMarkup;

      dealerProductPriceFactorModel.wmView = new InputViewModel();
      dealerProductPriceFactorModel.wmView.width = "60px";
      if (!this.wmViewVisible) {
        dealerProductPriceFactorModel.wmView.readOnly = true;
        dealerProductPriceFactorModel.wmView.hideIfDisabled = true;

      }
      else {
        dealerProductPriceFactorModel.wmView.value = defaultPriceFactor.wholesalerMarkup;

        dealerProductPriceFactorModel.rmView.readOnly = true;
      }
      dealerPriceCategoryModel.dealerProductPriceFactorModels.push(dealerProductPriceFactorModel);

      // Add all product models for this price category.
      if (products) {
        products.forEach(product => {
          let dealerProductPriceFactorModel = new DealerProductPriceFactorModel();
          dealerProductPriceFactorModel.product = product;
          //todo leta efter objectId istället för SERIES

          let priceFactor: CusPriceFactor = null;

          if (!allWorkGroups) {
            priceFactor = priceFactors.find(priceFactor => priceFactor.productId === product.longId) || this.modelFactory.createAny(CusPriceFactor.name);
          } else {

            let possibleMatchingPriceFactors = this.cusPriceFactors.filter(x => x.priceCategoryId === priceCategory.longId && x.productId == product.longId && workGroupIds.indexOf(x.workgroupId) > -1);

            priceFactor = this.modelFactory.createAny(CusPriceFactor.name);
            priceFactor = priceFactor.setProductId(product.longId);
            priceFactor = priceFactor.setWorkgroupId(-1);

            if (workGroupIds.length == possibleMatchingPriceFactors.length) {
              // We have one cusPriceFactors for each work group. If they have the same value, show it.
              if (possibleMatchingPriceFactors.every(x => x.markupType == possibleMatchingPriceFactors[0].markupType)) {
                priceFactor = priceFactor.setMarkupType(possibleMatchingPriceFactors[0].markupType);
              }
              if (possibleMatchingPriceFactors.every(x => x.wholesalerMarkup == possibleMatchingPriceFactors[0].wholesalerMarkup)) {
                priceFactor = priceFactor.setWholesalerMarkup(possibleMatchingPriceFactors[0].wholesalerMarkup);
              }
              if (possibleMatchingPriceFactors.every(x => x.retailMarkup == possibleMatchingPriceFactors[0].retailMarkup)) {
                priceFactor = priceFactor.setRetailMarkup(possibleMatchingPriceFactors[0].retailMarkup);
              }
            }
          }

          dealerProductPriceFactorModel.priceFactor = priceFactor;
          dealerProductPriceFactorModel.rmView = new InputViewModel();
          dealerProductPriceFactorModel.rmView.width = "60px";
          dealerProductPriceFactorModel.rmView.value = priceFactor.retailMarkup;
          dealerProductPriceFactorModel.wmView = new InputViewModel();
          dealerProductPriceFactorModel.wmView.width = "60px";
          if (!this.wmViewVisible) {
            // Hide Wholesale market for those how don't have authorization.
            dealerProductPriceFactorModel.wmView.readOnly = true;
            dealerProductPriceFactorModel.wmView.hideIfDisabled = true;
          }
          else {
            dealerProductPriceFactorModel.wmView.value = priceFactor.wholesalerMarkup;
          }

          dealerPriceCategoryModel.dealerProductPriceFactorModels.push(dealerProductPriceFactorModel);
        });
      }

      this.dealerPriceCategoryModels.push(dealerPriceCategoryModel);
    });
  }

  public populateExchangeRates() {
    this.exchangeRateOverrideModels = [];
    if (!this.currencies || !this.workgroupView)
      return;

    let allWorkGroups = this.workgroupView.value === "-1";

    this.currencies.forEach(currency => {
      // Get existing or create cusExchangeRateOverride object
      let cusExchangeRateOverride = this.cusExchangeRateOverrides.find(x => x.currencyId === currency.longId && x.workgroupId === parseInt(this.workgroupView.value)) || this.modelFactory.createAny(CusExchangeRateOverride.name);

      if (allWorkGroups) {
        // Remove user workGroup from list
        let allWorkGroupsButTheUsers = this.workGroups.filter(workGroup => workGroup.longId != this.user.workGroup.longId);
        let workGroupIds: Array<number> = [];
        allWorkGroupsButTheUsers.forEach(workGroup => {
          workGroupIds.push(workGroup.longId);
        });

        // ALL same values?
        let possibleMatchingOverrides = this.cusExchangeRateOverrides.filter(x => x.currencyId === currency.longId && workGroupIds.indexOf(x.workgroupId) > -1);

        if (workGroupIds.length == possibleMatchingOverrides.length) {
          // We have one cusExchangeRateOverride for each work group.
          if (possibleMatchingOverrides.every(x => x.factor == possibleMatchingOverrides[0].factor)) {
            cusExchangeRateOverride = cusExchangeRateOverride.setFactor(possibleMatchingOverrides[0].factor);
          }
          if (possibleMatchingOverrides.every(x => x.validUntil == possibleMatchingOverrides[0].validUntil)) {
            cusExchangeRateOverride = cusExchangeRateOverride.setValidUntil(possibleMatchingOverrides[0].validUntil);
          }
        }
      }

      // Create model for each row of exchangeRateOverride
      let exchangeRateOverrideModel = new ExchangeRateOverrideModel();
      exchangeRateOverrideModel.currency = currency;
      exchangeRateOverrideModel.overrideView = new InputViewModel();
      exchangeRateOverrideModel.validUntilView = new InputViewModel();
      //if (cusExchangeRateOverride) {
      exchangeRateOverrideModel.overrideView.value = cusExchangeRateOverride.factor;
      if (cusExchangeRateOverride.validUntil) {
        let date = new Date(cusExchangeRateOverride.validUntil);
        if (date.getFullYear() > 1) // Empty values shows as year 0001 - we don't set their value and instead let the model hade empty value.
          exchangeRateOverrideModel.validUntilView.value = cusExchangeRateOverride.validUntil;
      }
      exchangeRateOverrideModel.exchangeRateOverride = cusExchangeRateOverride;
      //}
      this.exchangeRateOverrideModels.push(exchangeRateOverrideModel);
    });
  }

  public populateOthers() {
    if (!this.workgroupView)
      return;

    // Create view models and set their title
    this.defaultDaysView = new InputViewModel(this.strings.getString('defaultdays'));
    this.introTextView = new InputViewModel(this.strings.getString('introtext'));
    this.termsTextView = new InputViewModel(this.strings.getString('termstext'));
    this.companyNameView = new InputViewModel(this.strings.getString('companyname'));
    this.adressLine1View = new InputViewModel(this.strings.getString('addressline1'));
    this.adressLine2View = new InputViewModel(this.strings.getString('addressline2'));
    this.postalCodeView = new InputViewModel(this.strings.PostalCode);
    this.countryView = new InputViewModel(this.strings.Country);
    this.vatView = new InputViewModel("VAT");
    this.phoneView = new InputViewModel(this.strings.Phone);
    this.mailView = new InputViewModel(this.strings.getString('mail'));
    this.webView = new InputViewModel(this.strings.WebAddress);
    this.cityView = new InputViewModel(this.strings.City);

    // Set view width
    this.defaultDaysView.width = this.companyNameView.width = this.adressLine1View.width = this.adressLine2View.width = this.postalCodeView.width = this.countryView.width = this.phoneView.width = this.mailView.width = this.webView.width = this.vatView.width = this.webView.width = this.cityView.width = "300px";


    let workGroup = this.workGroups.find(workGroup => workGroup.longId === parseInt(this.workgroupView.value));
    if (!workGroup) {
      if (this.workgroupView.value != "-1")
        return; // No work group and not ALL -> should not happen but we return if so.

      // If workGroup = -1 -> 'All' is selected. 

      // Set these to read only
      this.companyNameView.readOnly = true;
      this.adressLine1View.readOnly = true;
      this.adressLine2View.readOnly = true;
      this.postalCodeView.readOnly = true;
      this.countryView.readOnly = true;
      this.phoneView.readOnly = true;
      this.mailView.readOnly = true;
      this.webView.readOnly = true;
      this.vatView.readOnly = true;
      this.cityView.readOnly = true;
      // Clear values  
      this.defaultDaysView.value = "";
      this.introTextView.value = "";
      this.termsTextView.value = "";
      this.companyNameView.value = "";
      this.adressLine1View.value = "";
      this.adressLine2View.value = "";
      this.countryView.value = "";
      this.phoneView.value = "";
      this.mailView.value = "";
      this.webView.value = "";
      this.vatView.value = "";
      this.postalCodeView.value = "";
      this.cityView.value = "";

      if (this.workGroups.length > 0) {
        // If All work groups have the same value in cusDefaultDays, show the value. Skip your own work group!
        let defaultDaysView = this.workGroups[0].cusDefaultDays;
        if (!this.workGroups.find(workGroup => workGroup.cusDefaultDays != defaultDaysView && workGroup.longId != this.user.workGroup.longId)) {
          this.defaultDaysView.value = defaultDaysView;
        }

        // If All work groups have the same value in cusIntroText, show the value. Skip your own work group!
        let cusIntroText = this.workGroups[0].cusIntroText;
        if (!this.workGroups.find(workGroup => workGroup.cusIntroText != cusIntroText && workGroup.longId != this.user.workGroup.longId)) {
          this.introTextView.value = cusIntroText;
        }

        // If All work groups have the same value in cusTermsText, show the value. Skip your own work group!
        let cusTermsText = this.workGroups[0].cusTermsText;
        if (!this.workGroups.find(workGroup => workGroup.cusTermsText != cusTermsText && workGroup.longId != this.user.workGroup.longId)) {
          this.termsTextView.value = cusTermsText;
        }
      }
    }
    else {
      // Set values for view models
      this.defaultDaysView.value = workGroup.cusDefaultDays;
      this.introTextView.value = workGroup.cusIntroText;
      this.termsTextView.value = workGroup.cusTermsText;
      this.companyNameView.value = workGroup.cusCompanyName;
      this.adressLine1View.value = workGroup.cusAddressLine1;
      this.adressLine2View.value = workGroup.cusAddressLine2;
      this.postalCodeView.value = workGroup.cusPostalCode;
      this.countryView.value = workGroup.cusCountry;
      this.phoneView.value = workGroup.cusPhone;
      this.mailView.value = workGroup.cusMail;
      this.webView.value = workGroup.cusWeb;
      this.vatView.value = workGroup.cusVat;
      this.cityView.value = workGroup.cusCity;
    }
  }

  public savePriceFactors() {
    let dealerControlPanelRequest = this.modelFactory.createRequestOrCommand<DealerControlPanelRequest>(DealerControlPanelRequest.name);
    dealerControlPanelRequest.workGroupId = parseInt(this.workgroupView.value);
    dealerControlPanelRequest.storePriceFactors = true;
    dealerControlPanelRequest.priceFactorsData = [];

    this.dealerPriceCategoryModels.forEach(dealerPriceCategoryModel => {
      dealerPriceCategoryModel.dealerProductPriceFactorModels.forEach(dealerProductPriceFactor => {

        //Todo update objects in store...
        let priceFactor = dealerProductPriceFactor.priceFactor;
        priceFactor = priceFactor.setRetailMarkup(dealerProductPriceFactor.rmView.value);
        if (this.wmViewVisible) {
          // Only save WholesalerMarkup and type for those users with authorization.
          priceFactor = priceFactor.setWholesalerMarkup(dealerProductPriceFactor.wmView.value);
          priceFactor = priceFactor.setMarkupType(parseInt(dealerPriceCategoryModel.typeView.value));
        }
        priceFactor = priceFactor.setPriceCategoryId(dealerPriceCategoryModel.priceCategory.longId);
        priceFactor = priceFactor.setWorkgroupId(parseInt(this.workgroupView.value));

        let priceFactorArgument = new PriceFactorArgument();
        if (dealerProductPriceFactor.product)
          priceFactorArgument.productId = dealerProductPriceFactor.product.longId;
        else
          priceFactorArgument.productId = -1;
        priceFactorArgument.rm = dealerProductPriceFactor.rmView.value;
        if (this.wmViewVisible) {
          // Only save WholesalerMarkup and type for those useres with authorization.
          priceFactorArgument.wm = dealerProductPriceFactor.wmView.value;
          priceFactorArgument.type = parseInt(dealerPriceCategoryModel.typeView.value);
        }
        priceFactorArgument.priceCategoryId = dealerPriceCategoryModel.priceCategory.longId;
        if (dealerProductPriceFactor.priceFactor) {
          priceFactorArgument.longId = dealerProductPriceFactor.priceFactor.longId;
        }
        dealerControlPanelRequest.priceFactorsData.push(priceFactorArgument);
      });
    });

    //SAVE OBJECT
    let action = this.dealerControlPanelStore.storeObjects(dealerControlPanelRequest).subscribe(response => {
      // Force the reload of CusExchangeRateOverride entities
      this.globalDataStore.getEntities<CusPriceFactor>(CusPriceFactor.name, true);
      this.showResponse(response.data, this.strings.getString('pricefactorsupdatedsuccessfully'));
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public saveExchangeRates() {
    let dealerControlPanelRequest = this.modelFactory.createRequestOrCommand<DealerControlPanelRequest>(DealerControlPanelRequest.name);
    dealerControlPanelRequest.workGroupId = parseInt(this.workgroupView.value);
    dealerControlPanelRequest.storeExchangeRateOverrides = true;
    dealerControlPanelRequest.exchangeRateOverridesData = [];

    this.exchangeRateOverrideModels.forEach(exchangeRateOverrideModel => {
      //Todo update objects in store...
      let exchangeRateOverride = exchangeRateOverrideModel.exchangeRateOverride;
      if (exchangeRateOverride) {
        exchangeRateOverride = exchangeRateOverride.setCurrencyId(exchangeRateOverrideModel.currency.longId);
        exchangeRateOverride = exchangeRateOverride.setFactor(exchangeRateOverrideModel.overrideView.value);
        exchangeRateOverride = exchangeRateOverride.setValidUntil(exchangeRateOverrideModel.validUntilView.value);
      }

      let exchangeRateOverrideArgument = new ExchangeRateOverridesArgument();
      exchangeRateOverrideArgument.currencyId = exchangeRateOverrideModel.currency.longId;
      exchangeRateOverrideArgument.factor = exchangeRateOverrideModel.overrideView.value;
      exchangeRateOverrideArgument.validUntil = exchangeRateOverrideModel.validUntilView.value;
      if (exchangeRateOverrideModel.exchangeRateOverride) {
        exchangeRateOverrideArgument.longId = exchangeRateOverrideModel.exchangeRateOverride.longId;
      }
      dealerControlPanelRequest.exchangeRateOverridesData.push(exchangeRateOverrideArgument);
    });

    //SAVE OBJECT                 
    let action = this.dealerControlPanelStore.storeObjects(dealerControlPanelRequest).subscribe(response => {
      // Force the reload of CusExchangeRateOverride entities
      this.globalDataStore.getEntities(CusExchangeRateOverride.name, true);
      this.showResponse(response.data, this.strings.getString('exchangerateoverrideupdatedsuccessfully'));
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public saveOthers() {
    let dealerControlPanelRequest = this.modelFactory.createRequestOrCommand<DealerControlPanelRequest>(DealerControlPanelRequest.name);
    dealerControlPanelRequest.workGroupId = parseInt(this.workgroupView.value);
    dealerControlPanelRequest.storeWorkGroup = true;

    dealerControlPanelRequest.workGroupArgument = <WorkGroupArgument>{};
    dealerControlPanelRequest.workGroupArgument.cusDefaultDays = this.defaultDaysView.value;
    dealerControlPanelRequest.workGroupArgument.cusIntroText = this.introTextView.value || "";
    dealerControlPanelRequest.workGroupArgument.cusTermsText = this.termsTextView.value || "";
    dealerControlPanelRequest.workGroupArgument.cusCompanyName = this.companyNameView.value || "";
    dealerControlPanelRequest.workGroupArgument.cusAddressLine1 = this.adressLine1View.value || "";
    dealerControlPanelRequest.workGroupArgument.cusAddressLine2 = this.adressLine2View.value || "";
    dealerControlPanelRequest.workGroupArgument.cusPostalCode = this.postalCodeView.value || "";
    dealerControlPanelRequest.workGroupArgument.cusCountry = this.countryView.value || "";
    dealerControlPanelRequest.workGroupArgument.cusPhone = this.phoneView.value || "";
    dealerControlPanelRequest.workGroupArgument.cusMail = this.mailView.value || "";
    dealerControlPanelRequest.workGroupArgument.cusWeb = this.webView.value || "";
    dealerControlPanelRequest.workGroupArgument.cusCity = this.cityView.value || "";
    dealerControlPanelRequest.workGroupArgument.cusVat = this.vatView.value || "";

    // Listen for the success result of the store objects
    this.dealerControlPanelStore.storeObjects(dealerControlPanelRequest).subscribe(response => {
      // Force the reload of CusExchangeRateOverride entities
      this.accountStore.getEntities(WorkGroup.name, true);
      this.showResponse(response.data, this.strings.getString('quotetextsupdatedsuccessfully'));
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public showResponse(response: DealerControlPanelResponse, successMessage) {
    this.notificationService.notify(<NotificationInfo>{
      title: response ? this.strings.Success : this.strings.Error,
      message: response.success ? successMessage : response.errors.join(', '),
      type: response.success ? NotificationType.Success : NotificationType.Error,
      selfClose: true
    });
  }

  //public unsubscribe() {
  //  if (this.messageSubscription)
  //    this.messageSubscription.unsubscribe();
  //}

  ngOnDestroy() {
    //this.unsubscribe();

    this.appStoreSubscriptionManager.dispose();
    super.ngOnDestroy();
  }

  /**
 * Assortments by parentId.
 * @param parentId
 */
  getAssortments(parentAssortment: Assortment): ManagedSubject<StoreResponse<Assortment[]>> {
    // Create request object
    let response: StoreResponse<Assortment[]> = new StoreResponse<Assortment[]>();
        
    // Make direct return If store contains the children information.
    if (parentAssortment.children.size > 0) {
      response.data = this.productStore.getObjectsFromResultId<Assortment>(parentAssortment.children);

      if (response.data.every(x => !!x))
        return (this.productStore as any).valueSubscriptionManager.createSubject(response, true);
    }

    let model = this.productStore.createProductRequestModel();
    model.assortment = this.modelFactory.createRequestOrCommand<AssortmentCommand>(AssortmentCommand.name, { productLevel: 1, productFamilyLevel: 10, ids: parentAssortment.children.toArray() });

    let action = (this.productStore as any).productActionCreator.dispatchAssortments(model);

    return (this.productStore as any).createAction(action, (actionInfo) => {
      response.data = this.productStore.getObjectsFromResultId<Assortment>(parentAssortment.children);
      // TODO: Needed ?
      response.requestId = action.id;
      return response;
    });
  }

  helpClick($event): void {
    let helpValues: Array<ParamValue> = [];

    helpValues.push(<ParamValue>{ title: "RM", helpText: "Retail Markup" });
    helpValues.push(<ParamValue>{ title: "WM", helpText: "Wholesaler Markup" });

    this.dealerPriceCategoryModels.forEach(priceModel => {
      let helpText = priceModel.priceCategory.propertyMap.get('cusHelpText');

      if (helpText)
        helpValues.push(<ParamValue>{ helpText: priceModel.priceCategory.propertyMap.get('cusHelpText'), title: priceModel.priceCategory.name });
    });

    this.popupService.open<Array<ParamValue>>("dealer-help-popup", helpValues);
  }

}