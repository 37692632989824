import { BaseEntity } from "../../baseEntity";

export class WorkGroup extends BaseEntity {

  protected readonly TITLE = "title";
  protected readonly CUS_ADRESS_LINE_1 = "cusAddressLine1";
  protected readonly CUS_ADRESS_LINE_2 = "cusAddressLine2";
  protected readonly CUS_POSTAL_CODE = "cusPostalCode";
  protected readonly CUS_COMPANY_NAME = "cusCompanyName";
  protected readonly CUS_MAIL = "cusMail";
  protected readonly CUS_WEB = "cusWeb";

  protected readonly CUS_DEFAULT_DAYS = "cusDefaultDays";
  protected readonly CUS_PHONE = "cusPhone";
  protected readonly CUS_COUNTRY = "cusCountry";
  protected readonly CUS_TEXT = "cusIntroText";
  protected readonly CUS_TERMS_TEXT = "cusTermsText";
  protected readonly CUS_VAT = "cusVat";
  protected readonly CUS_CITY = "cusCity";
  protected readonly CUS_RESELLER_TYPE = "cusResellerType";
  protected readonly CUS_MARKET = "cusMarket";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get cusDefaultDays(): number { return this.getInternalValue<number>(this.CUS_DEFAULT_DAYS); }
  setCusDefaultDays(cusDefaultDays: number): this { return this.setInternalValue(this.CUS_DEFAULT_DAYS, cusDefaultDays); }

  get cusPhone(): string { return this.getInternalValue<string>(this.CUS_PHONE); }
  setCusPhone(cusPhone: string): this { return this.setInternalValue(this.CUS_PHONE, cusPhone); }

  get cusCountry(): string { return this.getInternalValue<string>(this.CUS_COUNTRY); }
  setCusCountry(cusCountry: string): this { return this.setInternalValue(this.CUS_COUNTRY, cusCountry); }

  get cusIntroText(): string { return this.getInternalValue<string>(this.CUS_TEXT); }
  setCusIntroText(cusIntroText: string): this { return this.setInternalValue(this.CUS_TEXT, cusIntroText); }

  get cusTermsText(): string { return this.getInternalValue<string>(this.CUS_TERMS_TEXT); }
  setCusTermsText(cusTermsText: string): this { return this.setInternalValue(this.CUS_TERMS_TEXT, cusTermsText); }

  get cusAddressLine1(): string { return this.getInternalValue<string>(this.CUS_ADRESS_LINE_1); }
  setCusAddressLine1(cusAddressLine1: string): this { return this.setInternalValue(this.CUS_ADRESS_LINE_1, cusAddressLine1); }

  get cusAddressLine2(): string { return this.getInternalValue<string>(this.CUS_ADRESS_LINE_2); }
  setCusAddressLine2(cusAddressLine2: string): this { return this.setInternalValue(this.CUS_ADRESS_LINE_2, cusAddressLine2); }

  get cusPostalCode(): string { return this.getInternalValue<string>(this.CUS_POSTAL_CODE); }
  setCusPostalCode(cusPostalCode: string): this { return this.setInternalValue(this.CUS_POSTAL_CODE, cusPostalCode); }

  get cusCompanyName(): string { return this.getInternalValue<string>(this.CUS_COMPANY_NAME); }
  setCusCompanyName(cusCompanyName: string): this { return this.setInternalValue(this.CUS_COMPANY_NAME, cusCompanyName); }

  get cusMail(): string { return this.getInternalValue<string>(this.CUS_MAIL); }
  setCusMail(cusMail: string): this { return this.setInternalValue(this.CUS_MAIL, cusMail); }

  get cusWeb(): string { return this.getInternalValue<string>(this.CUS_WEB); }
  setCusWeb(cusWeb: string): this { return this.setInternalValue(this.CUS_WEB, cusWeb); }

  get cusCity(): string { return this.getInternalValue<string>(this.CUS_CITY); }
  setCusCity(cusCity: string): this { return this.setInternalValue(this.CUS_CITY, cusCity); }

  get cusVat(): string { return this.getInternalValue<string>(this.CUS_VAT); }
  setCusVat(cusVat: string): this { return this.setInternalValue(this.CUS_VAT, cusVat); }

  get cusResellerType(): number { return this.getInternalValue<number>(this.CUS_RESELLER_TYPE); }
  setCusResellerType(cusResellerType: number): this { return this.setInternalValue(this.CUS_RESELLER_TYPE, cusResellerType); }

  get cusMarket(): number { return this.getInternalValue<number>(this.CUS_MARKET); }
  setCusMarket(cusMarket: number): this { return this.setInternalValue(this.CUS_MARKET, cusMarket); }

}