import { BaseRequest } from "../../../../pages/shared/models/requests/baseRequest";
import { ExchangeRateOverridesArgument } from "../../../../pages/shared/models/requests/arguments/exchangeRateOverridesArgument";
import { PriceFactorArgument } from "../../../../pages/shared/models/requests/arguments/priceFactorArgument";
import { WorkGroupArgument } from "../../../../pages/shared/models/requests/arguments/workGroupArgument";

export class DealerControlPanelRequest extends BaseRequest {

  getRootAssortments?: boolean;

  workGroupId : number

  storeWorkGroup?: boolean;
  
  storePriceFactors?: boolean;

  storeExchangeRateOverrides?: boolean;

  workGroupArgument?: WorkGroupArgument;
  
  exchangeRateOverridesData?: Array<ExchangeRateOverridesArgument>;

  priceFactorsData?: Array<PriceFactorArgument>;
}