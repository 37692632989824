<action-box *ngIf="visible"
            [showHeader]="showHeader"
            textLeft="{{strings.State}}"
            [iconRight]="showHistoryButton && 'history'"
            [textRight]="strings.History"
            (onRightIconClick)="onHistoryClick()">
  <ng-container>
    <div class="states">
      <state-item *ngFor="let state of states"
                  [state]="state"
                  [class.active]="state.active">
      </state-item>
      <div class="text-right">

        <button *ngIf="enablePlaceOrder" class="btn btn-darker mt-2 mr-2 text-uppercase" (click)="onPromoteClick()">Place order</button>
        <button class="btn btn-light mt-2 mr-2 text-uppercase" [disabled]="!enablePromote || enablePlaceOrder"  (click)="onPromoteClick()">{{strings.Promote}}</button>
        <button class="btn btn-light mt-2 text-uppercase" [disabled]="!enableDemote" (click)="onDemoteClick()">{{strings.Demote}}</button>
      </div>
    </div>
  </ng-container>
</action-box>
<state-history-popup *ngIf="showHistoryPopup" (onClosed)="onPopupClosed()"></state-history-popup>
<state-action-popup *ngIf="showActionPopup" (onClosed)="onPopupClosed()" (preventShow)="onPopupClosed()"></state-action-popup>