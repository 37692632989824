import { Inject, Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ParamValue, Param } from "../shared/models";
import { PopupService } from "../../shared/components/popup";
import { AccordionComponent } from "../../shared/components";
import { AbstractPopupComponent } from "../../shared/components/popup/abstractPopupComponent";

@Component({
  selector: 'dealer-help-popup',
  templateUrl: './dealerHelpPopupComponent.html'
})
export class DealerHelpPopupComponent extends AbstractPopupComponent {

  public id: string = "dealer-help-popup";

  public parameter: Param;

  public values: Array<ParamValue> = [];

  public defaultValue: number;

  public helpWidth: string = "400px";

  @ViewChild(AccordionComponent)
  public helpAccordion: AccordionComponent;

  constructor(
    @Inject(PopupService) public popupService: PopupService,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  public open(tag: Array<ParamValue>) {

    this.parameter = <Param>{ title: "PRICE FACTORS", helpText: "" };

    this.values = tag;
    
    this.showInternal();

  }
}