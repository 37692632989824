import { InputViewModel } from "../../shared/components";
import { Product, CusPriceFactor } from "../shared/models";

export class DealerProductPriceFactorModel {
  public product: Product;
  public priceFactor: CusPriceFactor;
  public rmView: InputViewModel;
  public wmView: InputViewModel;
  public markupView: InputViewModel;
}

