import { Inject, Injectable } from '@angular/core';
import * as Immutable from "immutable";
import { FilterType } from "../../../../shared/components/shared";
import { ICallback, IObserverOptions, ManagedSubscription, SubscriptionOptions } from "../../../../shared/managedSubscription";
import { ConfDataRequest, DataSelectorCommand, GridArgument, PaginationArgument, RequestViews, SearchArgument, SortingArgument } from "../../../shared/models";
import { DataSelectorGridMessage, DataSelectorKeyDetailMessage } from "../../../shared/models/responses/messages";
import { AppStoreSubscriptionManager, ModelFactory } from "../../../shared/providers";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { AppStore, BaseStore } from "../../../shared/state";
import { ConfiguratorStore, ConfMessageProvider, ConfPageSessionService } from "../../providers";
import { DataSelectorActionCreator } from "./dataSelectorActionCreator";

@Injectable()
export class DataSelectorStore extends BaseStore {

  constructor(
    @Inject(AppStore) public appStore: AppStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(DataSelectorActionCreator) public actionCreator: DataSelectorActionCreator,
    @Inject(ConfMessageProvider) public messagesProvider: ConfMessageProvider,
    @Inject(AppStoreSubscriptionManager) public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    public globalDataStore: GlobalDataStore
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory);
  }

  public createRequest(rawObject?: Partial<ConfDataRequest>): ConfDataRequest {
    let request = this.modelFactory.createRequestOrCommand<ConfDataRequest>(ConfDataRequest.name, rawObject);
    request.client = RequestViews.Editor;
    return request;
  }

  public onRowMessageRequest(confSessionId: number, dataSelectorId: number, subscriptionOptions: SubscriptionOptions<DataSelectorGridMessage>): ManagedSubscription {
    let observer = <IObserverOptions<any>>subscriptionOptions;
    if (!observer.next)
      observer.next = <ICallback<any>>subscriptionOptions;

    // Override observer and filter on dataSelectorId
    let originalObserver = observer.next;
    observer.next = (messages: Immutable.List<DataSelectorGridMessage>) => {
      let message = messages.find(x => x.dataSelectorId === dataSelectorId);
      if (message)
        originalObserver(message);
    }

    return this.messagesProvider.onMessagesRequest<DataSelectorGridMessage>(confSessionId, DataSelectorGridMessage.name, observer);
  }

  public dispatchGetRows(configurationId: number, confSessionId: number, dataSelectorId: number, pageSize: number, clearSearch?: boolean, pageIndex?: number, key?: string, descending?: boolean) {

    let model = this.createRequest();
    model.confSessionId = confSessionId;    
    model.dataSelector = this.modelFactory.createRequestOrCommand<DataSelectorCommand>(DataSelectorCommand.name, {

      responseFormat: this.globalDataStore.getConfResponseFormat(RequestViews.Editor),
      configurationId: configurationId, dataSelectorId: dataSelectorId,      
      clearSearch: clearSearch,

      gridData: this.modelFactory.createAny<GridArgument>(GridArgument.name, {

        pagination: this.modelFactory.createAny<PaginationArgument>(PaginationArgument.name, {
          pageSize: pageSize,
          pageIndex: clearSearch ? null : pageIndex
        }),
        sorting: this.modelFactory.createAny<SortingArgument>(SortingArgument.name, {field: key, descending: descending })
      })
    });
    
    this.appStore.dispatch(this.actionCreator.getDataSelectorRows(model));
  }

  public dispatchGetKeyDetail(configurationId: number, confSessionId: number, dataSelectorId: number, keyId: number) {
    let model = this.createRequest({ confSessionId: confSessionId });
    model.confSessionId = confSessionId;
    model.dataSelector = this.modelFactory.createRequestOrCommand<DataSelectorCommand>(DataSelectorCommand.name, {
      configurationId: configurationId, dataSelectorId: dataSelectorId, keyDetail: keyId
    });
    model.dataSelector.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Editor);

    this.appStore.dispatch(this.actionCreator.getDataSelectorKeyDetail(model));
  }

  public onKeyDetailMessageRequest(confSessionId: number, dataSelectorId: number, keyId: number, callback: (messages: Immutable.List<DataSelectorKeyDetailMessage>) => void): ManagedSubscription {
    return this.messagesProvider.onMessagesRequest<DataSelectorKeyDetailMessage>(confSessionId, DataSelectorKeyDetailMessage.name, callback);
  }

  public setDataSelectorValue(configurationId: number, confSessionId: number, dataSelectorId: number, keyId: number): void {

    let model = this.createRequest({ confSessionId: confSessionId });    
    model.dataSelector = this.modelFactory.createRequestOrCommand<DataSelectorCommand>(DataSelectorCommand.name, {
      configurationId: configurationId, dataSelectorId: dataSelectorId, setKey: keyId
    });

    model.dataSelector.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Editor);

    this.actionCreator.dispatchSetDataSelectorValue(model);
  }

  public clearValue(configurationId: number, confSessionId: number, dataSelectorId: number): void {

    let model = this.createRequest({ confSessionId: confSessionId });
    model.dataSelector = this.modelFactory.createRequestOrCommand<DataSelectorCommand>(DataSelectorCommand.name, {
      configurationId: configurationId, dataSelectorId: dataSelectorId, clearKey: true
    });

    model.dataSelector.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Editor);

    this.actionCreator.dispatchClearSelectorValue(model);
  }

  public searchDataSelectorValues(configurationId: number, confSessionId: number, dataSelectorId: number, fieldId: number, value1: string, value2: string, filterType: FilterType): void {

    let model = this.createRequest({ confSessionId: confSessionId });
    model.dataSelector = this.modelFactory.createRequestOrCommand<DataSelectorCommand>(DataSelectorCommand.name, {
      configurationId: configurationId, dataSelectorId: dataSelectorId 
    });
    model.dataSelector.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Editor);

    model.dataSelector.search = this.modelFactory.createAny<SearchArgument>(SearchArgument.name, {
      fieldId: fieldId, value1: value1, value2: value2, filterType: filterType
    });
        
    model.dataSelector.gridData = this.modelFactory.createAny<GridArgument>(GridArgument.name);
    
    this.actionCreator.dispatchSearchDataSelectorValues(model);
  }

  public clearDataSelectorSearch(configurationId: number, confSessionId: number, dataSelectorId: number): void {
    
    let model = this.createRequest({ confSessionId: confSessionId });
    model.dataSelector = this.modelFactory.createRequestOrCommand<DataSelectorCommand>(DataSelectorCommand.name, {
      configurationId: configurationId, dataSelectorId: dataSelectorId, clearSearch: true
    });    
    model.dataSelector.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Editor);
    model.dataSelector.gridData = this.modelFactory.createAny<GridArgument>(GridArgument.name);
    
    this.actionCreator.dispatchClearDataSelectorSearch(model);
  }
}