<regular-popup *ngIf="visible"
               [id]="id"
               tabindex="-1"
               [width]="helpWidth"
               [draggable]="false"
               [title]="strings.Help"
               [okButtonText]="strings.Ok"
               [cancelButtonText]="strings.Cancel"
               (onClosed)="onPopupClosed()">
  <!--Body part-->
  <div card-type="body" *ngIf="parameter != null">
    <accordion [openFirst]="false" #helpAccordion
               classes="accordion">
      <accordion-group id="helpParameter"
                       [heading]="parameter.title"
                       icon="info"
                       [darkTheme]="true"
                       [iconIsVault]="false"
                       [togglable]="values.length > 0">
        <div class="m-4">
          <span class="multiline" [innerHtml]="parameter.helpText | safeHtml"></span>
        </div>
      </accordion-group>
      <accordion-group *ngFor="let value of values"
                       id="helpValues"
                       [scrollInToView]="true"
                       [heading]="value.title"
                       [isOpened]="false">
        <div class="m-4">
          <span class="multiline" [innerHtml]="value.helpText | safeHtml"></span>
        </div>
      </accordion-group>
    </accordion>
  </div>
</regular-popup>