import { BaseEntity } from "../../../../pages/shared/baseEntity"

export abstract class CusExchangeRateOverride extends BaseEntity {

  protected readonly WORKGROUP_ID = "workgroupId";
  protected readonly CURRENCY_ID = "currencyId";
  protected readonly FACTOR = "factor";
  protected readonly VALID_UNTIL = "validUntil";
  protected readonly WORK_GROUP_ID = "workGroup";

  get workgroupId(): number { return this.getInternalValue<number>(this.WORKGROUP_ID); }
  setWorkgroupId(workgroupId: number): this { return this.setInternalValue(this.WORKGROUP_ID, workgroupId); }

  get currencyId(): number { return this.getInternalValue<number>(this.CURRENCY_ID); }
  setCurrencyId(currencyId: number): this { return this.setInternalValue(this.CURRENCY_ID, currencyId); }

  get factor(): number { return this.getInternalValue<number>(this.FACTOR); }
  setFactor(factor: number): this { return this.setInternalValue(this.FACTOR, factor); }

  get validUntil(): string { return this.getInternalValue<string>(this.VALID_UNTIL); }
  setValidUntil(validUntil: string): this { return this.setInternalValue(this.VALID_UNTIL, validUntil); }

  get workGroup(): number { return this.getInternalValue<number>(this.WORK_GROUP_ID); }
  setWorkGroup(workGroup: number): this { return this.setInternalValue(this.WORK_GROUP_ID, workGroup); }
}