<backdrop-container>
  <regular-popup *ngIf="visible"
                 [id]="id"
                 [height]="height"
                 [width]="width"
                 [showFooter]="true"
                 [title]="titleText"
                 [okButtonText]="strings.Ok"
                 [cancelButtonText]="strings.Cancel"
                 [okButton]="infoMessage?.isAllowed"
                 [closeOnOk]="false"
                 (okClick)="okClick()"
                 (onClosed)="onClosed.emit()">
    <div card-type="body" class="p-4">
      <ng-container *ngIf="infoMessage">
        <div class="d-flex align-middle pb-2">
          <div>
            <b>{{fromState.name}}</b>
          </div>
          <div>
            <co-image key="arrowright" width="15px" height="15px"></co-image>
          </div>
          <div>
            <dropdown *ngIf="toStateView; else singleState"
                      class=""
                      [itemsView]="toStateViews"
                      [view]="toStateView">
            </dropdown>
            <ng-template #singleState>
              <b>{{toState.name}}</b>
            </ng-template>
          </div>
        </div>
        <!--Customization start-->
        <!--<p *ngIf="infoMessage?.message" class="action-message">
           {{infoMessage.message}}
        </p>-->

        <p *ngIf="infoMessage?.message" class="action-message" [innerHtml]="infoMessage.message | safeHtml"> </p>

        <!--Customization end-->
        <div *ngIf="infoMessage?.isAllowed">
          <text-box [view]="noteView"
                    autofocus="true"
                    rows="6">
          </text-box>
          <div *ngIf="!infoMessage.errors.isEmpty()" class="action-error my-2">
            <b>{{errorText}}</b>
            <div *ngFor="let error of infoMessage.errors">{{error}}</div>
          </div>
          <div class="pt-4" *ngIf="!infoMessage.warnings.isEmpty()">
            <div *ngFor="let warning of infoMessage.warnings"><p class="action-warning ">{{warning}}</p></div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="resultMessage">
        <div *ngIf="!resultMessage.errors.isEmpty()" class="action-error my-2">
          <div class="text-break" *ngFor="let error of resultMessage.errors">{{error}}</div>
        </div>
        <div *ngIf="!resultMessage.warnings.isEmpty()" class="action-warning">
          <div class="text-break" *ngFor="let warning of infoMessage.warnings">{{warning}}</div>
        </div>
      </ng-container>
    </div>
  </regular-popup>
</backdrop-container> 