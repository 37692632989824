import * as Immutable from "immutable"

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"

export class DataSelectorKeyDetailMessage extends AbstractConfigurationMessage {

  protected readonly KEY_ID = "keyId";
  protected readonly DETAILS = "details";

  get keyId(): number { return this.getInternalValue<number>(this.KEY_ID); }
  setKeyId(keyId: number): this { return this.setInternalValue(this.KEY_ID, keyId); }

  /** 
  Key: Parameter ID or MultiChoiceValueID for multichoices.
  Value: old value (index 0) and the new value (index 1).
  */
  get details(): Immutable.Map<string, Array<string>> { return this.getInternalValue<Immutable.Map<string, Array<string>>>(this.DETAILS); }
  setDetails(details: Immutable.Map<string, Array<string>>): this { return this.setInternalValue(this.DETAILS, details); }
}