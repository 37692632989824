import { BaseMessage } from "./baseMessage";

export class DealerControlPanelMessage extends BaseMessage {

  protected readonly SUCCESS = "success";
  protected readonly ERRORS = "errors";

  get success(): boolean { return this.getInternalValue<boolean>(this.SUCCESS); }
  setSuccess(success: boolean): this { return this.setInternalValue(this.SUCCESS, success); }

  get errors(): Array<string> { return this.getInternalValue<Array<string>>(this.ERRORS); }
  setErrors(errors: Array<string>): this { return this.setInternalValue(this.ERRORS, errors); }

}