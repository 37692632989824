import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from "@angular/router";

import { RouteRedirector } from "../shared/providers/routeRedirector";
import { AccountDataStore } from "../shared/providers/accountData/accountDataStore";
import { RouteNames, AuthGuard } from "../shared/providers";
import { ConfRouteParams } from "../configurator/providers/confRouteParams";
import { GlobalSettings, AuthorizationGroup, DoosanGlobalSettings } from "../shared/models";
import { GlobalDataStore } from "../shared/providers/globalData";

@Injectable()
export class DealerAuthGuard extends AuthGuard implements CanActivate {

  constructor(public routerRedirector: RouteRedirector,
    public accountStore: AccountDataStore,
    public globalDataStore: GlobalDataStore
  ) {
    super(routerRedirector, accountStore);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let canActivate = super.canActivate(route, state);
        
    return canActivate.then((result) => {

      if (result) {

        return new Promise<boolean>((resolve, reject) => {

          this.accountStore.getEntities<AuthorizationGroup>(AuthorizationGroup.name).subscribe(response => {

            let globalSettings = this.globalDataStore.getGlobalData().globalSettings;                        
            resolve(globalSettings.propertyMap.get("canAccessDealerControlPanel"));
          });

        });
        
      }

      return Promise.resolve(false);    
    });
  }
}