import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { PopupService, DataRowView } from "../../../../shared/components";
import { DataSelectorGridMessage } from "../../../shared/models";
import { ProductDataStore } from "../../../shared/providers/productData";
import { VisualObjectHelper } from "../../parameters/shared";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { ConfMessageProvider } from "../../providers/confMessageProvider";
import { AbstractDataSelectorGridComponent } from "./abstractDataSelectorGridComponent";
import { DataSelectorStore } from "./dataSelectorStore";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { PagerService } from "../../../shared/components/pager/pagerService";
import { ImageSets } from "../../../../shared/utils";


@Component({
  selector: 'data-selector-table-modal',
  templateUrl: './dataSelectorTableModalComponent.html'
})
export class DataSelectorTableModalComponent extends AbstractDataSelectorGridComponent {

  public id: string = "data-selector-popup";
  public visible = false;

  public tableMinWidth: string;

  public isDataReady: boolean = false;

  public title: string;

  protected lastClick: number;

  @Input()
  isSidebar: boolean;

  @Output()
  onClosed = new EventEmitter();

  constructor(
    @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(DataSelectorStore) public dataSelectorStore: DataSelectorStore,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    public cdr: ChangeDetectorRef,
    public globalDataStore: GlobalDataStore,
    public pagerService: PagerService
  ) {
    super(confMessageProvider, dataSelectorStore, productStore, confStore, storeSession, visualObjectHelper, globalDataStore, pagerService);
  }

  ngOnInit() {

    this.title = this.decoration.title != '' ? this.title = this.decoration.title : this.title = this.strings.DataSelector;

    // standard width: if not set then 90% if popup, read from ui settings if sidebar
    this.width = this.decoration.dialogWidth ? this.decoration.dialogWidth + 'px' : this.isSidebar ? this.uiSettings.configurator.decoration.dataSelector.dialogWidth : '90%';
    // table minwidth (enables scrolling): set if width is a percentage or bigger than window size
    this.tableMinWidth = this.width === '90%' ? this.uiSettings.configurator.decoration.dataSelector.dialogWidth : parseInt(this.width) > window.innerWidth ? this.width : "";

    if (this.decoration && this.decoration.pageSize)
      this.pagerService.updatePageSizes(this.decoration.pageSize, this.pagerModel);

    this.filterVisible = this.globalDataStore.globalSettings.dataSelectorFilterControlsVisible;
  }

  public handleSetSelectedValueError() {
    // TODO we should use a popup dialog to display the error.
  }
  public handleSetSelectedValueSuccess() {
    // We've successfully set value -> close table -> inform
  }

  public onPopupClosed(): void {
    this.visible = false;
    this.onClosed.emit();
  }

  listenChanges() {
    this.dataSelectorStore.onRowMessageRequest(this.storeSession.confSessionId, this.decoration.visualObjectId, {
      next: (gridMessage: DataSelectorGridMessage): void => {
        let gridData = gridMessage.gridData;
        this.createTable(gridData);

        this.pagerService.update(gridData.pagination, this.pagerModel);
        this.refreshPager = !this.refreshPager;

        // Update search info. Could be changed from server.
        this.searchInfo = gridData.search;
        this.isFiltered = this.searchInfo != null;
        this.showFilter = this.showFilter && this.searchInfo != null;

        this.isDataReady = true;

        this.visible = true;

        setTimeout(() => {
          this.popupService.open(this.id);
          this.cdr.detectChanges();
        }, 100);
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);

    this.refreshPage(true);
  }

  public show(): void {
    this.listenChanges();
  }

  public onValueClick(value: DataRowView) {
    if (this.selectedValue && value && this.selectedValue.id == +value.id && Date.now() - this.lastClick < 500) {
      this.setSelectedValue();
      this.popupService.close(this.id);
    }
    else {
      super.onValueClick(value);
      this.lastClick = Date.now();
    }
  }
} 