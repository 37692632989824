import * as Immutable from "immutable";

import { BaseEntity } from "../../../../pages/shared/baseEntity";
import { SearchResult } from "../../../../pages/shared/models/responses/searchResult";
import { ConfigurationSearch } from "../../../../pages/shared/models/entities/configurationSearch";
import { Assortment } from "../../../../pages/shared/models/entities/assortment";
import { AbstractSearchDataMessage } from "../../../../pages/shared/models/responses/messages/abstractSearchDataMessage";


export class DealerControlPanelResponse extends BaseEntity {

  protected readonly ASSORTMENTS = "assortments";
  protected readonly PRODUCTS = "products";
  protected readonly GROUPINGS = "groupings";
  protected readonly SUCCESS = "success";
  protected readonly ERRORS = "errors";

  get assortments(): Immutable.List<Assortment> { return this.getInternalValue<Immutable.List<Assortment>>(this.ASSORTMENTS); }
  setAssortments(assortments: Immutable.List<Assortment>): this { return this.setInternalValue(this.ASSORTMENTS, assortments); }

  get products(): Immutable.List<Assortment> { return this.getInternalValue<Immutable.List<Assortment>>(this.PRODUCTS); }
  setProducts(products: Immutable.List<Assortment>): this { return this.setInternalValue(this.PRODUCTS, products); }

  get groupings(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.GROUPINGS); }
  setGroupings(groupings: Immutable.List<string>): this { return this.setInternalValue(this.GROUPINGS, groupings); }

  get success(): boolean { return this.getInternalValue<boolean>(this.SUCCESS); }
  setSuccess(success: boolean): this { return this.setInternalValue(this.SUCCESS, success); }

  get errors(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.ERRORS); }
  setErrors(errors: Immutable.List<string>): this { return this.setInternalValue(this.ERRORS, errors); }
}