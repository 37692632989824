import { Injectable, Inject } from "@angular/core";
import * as Immutable from 'immutable';
import { Strings } from "../../shared/providers/globalData";
import { DisallowedValueMessage, ReadOnlyParamMessage, ClearedParamMessage, MandatoryParamsMessage, ConfSaveMessage, PriceReportMessage, PriceSettingMessage, RuleConfirmationMessage, RuleInformationMessage, ConfErrorMessage, AbstractImmutableValueMessage, MultiChoiceValue, MultiChoiceParam, Param } from "../../shared/models";
import { ConfMessageProvider } from "./confMessageProvider";
import { NotificationService, NotificationInfo, NotificationType, PopupService, NotificationCell, NotificationRow } from "../../../shared/components";
import { ConfPageSessionService } from "./confPageSessionService";
import { PopupIdentifiers } from "./popupIdentifiers";
import { ParameterMandatoryService } from "../parameters/shared/parameterMandatoryService";
import { ConfiguratorStore } from "./configuratorStore";
import { ManagedSubject } from "../../../shared/managedSubject";
import { ConfiguratorUIStore } from "./configuratorUIStore";
import { PageActionCreator } from "../../shared/providers/page";
import { WarningAttributesMessage, ClearedPricesMessage, DataSelectorKeyDetailMessage } from "../../shared/models/responses";
import { ProductDataStore } from "../../shared/providers/productData";

@Injectable()
export class ConfMessageHandler {

  public unsubscribeSubject: ManagedSubject<any> = new ManagedSubject<any>(null, true);

  constructor(
    @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(Strings) public strings: Strings,
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(ParameterMandatoryService) public parameterMandatoryService: ParameterMandatoryService,
    @Inject(ConfiguratorStore) public configuratorStore: ConfiguratorStore,
    @Inject(PageActionCreator) public pageActionCreator: PageActionCreator,
    @Inject(NotificationService) public notificationService: NotificationService,
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,

  ) {
  }

  subscribeMessagesForEditor(): void {
    if (!this.confPageSession.confSessionId)
      return;

    // Start listening for WarningAttributesMessage
    this.listenForWarningAttributesMessages();

    // Disallowed message 
    this.confMessageProvider.onMessagesRequest<DisallowedValueMessage>(this.confPageSession.confSessionId, DisallowedValueMessage.name,
      {
        next: (messages: Immutable.List<DisallowedValueMessage>) => {
          this.popupService.open<AbstractImmutableValueMessage>(PopupIdentifiers.Disallowed, messages.get(0));
        },
        listenNewEventsOnly: true
      }
    ).unsubscribeOn(this.unsubscribeSubject);

    // Read only message
    this.confMessageProvider.onMessagesRequest<ReadOnlyParamMessage>(this.confPageSession.confSessionId, ReadOnlyParamMessage.name, {
      next: (messages: Immutable.List<ReadOnlyParamMessage>) => {
        this.popupService.open<AbstractImmutableValueMessage>(PopupIdentifiers.ReadOnly, messages.get(0));
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);

    this.confMessageProvider.onMessagesRequest<ClearedParamMessage>(this.confPageSession.confSessionId, ClearedParamMessage.name, {
      next: (messages: Immutable.List<ClearedParamMessage>) => {
        this.popupService.open<Immutable.List<ClearedParamMessage>>(PopupIdentifiers.ClearValue, messages);
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);

    // Price setting message
    this.confMessageProvider.onMessagesRequest<PriceSettingMessage>(this.confPageSession.confSessionId, PriceSettingMessage.name, {
      next: (messages: Immutable.List<PriceSettingMessage>): void => {
        this.popupService.open<PriceSettingMessage>(PopupIdentifiers.PriceSetting, messages.get(0));
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);

    // Rule confirmation message
    this.confMessageProvider.onMessagesRequest<RuleConfirmationMessage>(this.confPageSession.confSessionId, RuleConfirmationMessage.name, {
      next: (messages: Immutable.List<RuleConfirmationMessage>): void => {
        this.popupService.open<RuleConfirmationMessage>(PopupIdentifiers.RuleConfirm, messages.get(0));
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);

    // Rule information message
    this.confMessageProvider.onMessagesRequest<RuleInformationMessage>(this.confPageSession.confSessionId, RuleInformationMessage.name, {
      next: (messages: Immutable.List<RuleInformationMessage>): void => {
        this.popupService.open<Immutable.List<RuleInformationMessage>>(PopupIdentifiers.RuleInformation, messages);
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);

    this.confMessageProvider.onMessagesRequest<ClearedPricesMessage>(this.confPageSession.confSessionId, ClearedPricesMessage.name, {
      next: (messages: Immutable.List<ClearedPricesMessage>): void => {
        this.popupService.open<Immutable.List<ClearedPricesMessage>>(PopupIdentifiers.WarningPricePopup, messages);
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);

  }

  subscribeMessagesForSummary(): void {
    // Start listening for WarningAttributesMessage
    this.listenForWarningAttributesMessages();
  }

  subscribeErrorMessages(): void {
    //this.confMessageProvider.onMessagesRequest<ErrorMessage>(this.confPageSession.confSessionId, ErrorMessage.name, (messages: Immutable.List<ErrorMessage>): void => {
    //  let exception = messages.first();
    //  this.notificationService.notify(<NotificationInfo>{
    //    title: this.strings.Error,
    //    message: exception.errorMessage,
    //    detail: exception.stackTrace,
    //    type: NotificationType.Error,
    //    selfClose: false,
    //    identifier: "error-message"
    //  });
    //});
  }

  listenForWarningAttributesMessages() {

    // Listen for WarningAttributesMessage and show popup    
    this.confMessageProvider.onMessagesRequest<WarningAttributesMessage>(this.confPageSession.confSessionId, WarningAttributesMessage.name, {
      listenNewEventsOnly: true,      
      next: (messages) => {        
        this.popupService.open<Immutable.List<WarningAttributesMessage>>(PopupIdentifiers.WarningAttributes, messages);
      }
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  onDestroy() {
    this.unsubscribeSubject.nextValue(null);
  }

}