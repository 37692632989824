import { ChangeDetectorRef, Component, EventEmitter, Inject, Output } from "@angular/core";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { DataSelector } from "../../../shared/models";
import { GridRow } from "../../../shared/models/responses";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";


@Component({
  selector: 'dataselector-value-detail-popup',
  templateUrl: './dataSelectorValueDetailPopupComponent.html',
})
export class DataSelectorValueDetailPopupComponent extends AbstractPopupComponent {

  public dataSelector: DataSelector;
  public selectedValue: GridRow;

  @Output()
  public okClicked = new EventEmitter();

  public onOkClicked() {
    this.okClicked.emit();
  }

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  public show(dataSelector: DataSelector, selectedValue: GridRow) {
    this.dataSelector = dataSelector;
    this.selectedValue = selectedValue;
    this.showInternal();
  }
}