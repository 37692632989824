export class WorkGroupArgument {
  longId: number;
  cusAddressLine1: string;
  cusAddressLine2: string;
  cusPostalCode: string;
  cusVat: string;
  cusResellerType: number;
  cusCity: string;
  cusCompanyName: string;
  cusMail: string;
  cusWeb: string;
  cusDefaultDays: number;
  cusPhone: string;
  cusCountry: string;
  cusIntroText: string;
  cusTermsText: string;
}