import { ChangeDetectorRef, Component, Inject, SimpleChanges, ViewChild } from "@angular/core";
import { DataRowView } from "../../../../shared/components/shared";
import { ImageSets } from "../../../../shared/utils/imageSets";
import { GridRow } from "../../../shared/models/responses";
import { ProductDataStore } from "../../../shared/providers/productData";
import { VisualObjectHelper } from "../../parameters/shared";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { ConfMessageProvider } from "../../providers/confMessageProvider";
import { AbstractDataSelectorGridComponent } from "./abstractDataSelectorGridComponent";
import { DataSelectorStore } from "./dataSelectorStore";
import { DataSelectorValueDetailPopupComponent } from "./dataSelectorValueDetailPopupComponent";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { PagerService } from "../../../shared/components/pager/pagerService";

@Component({
  selector: 'data-selector-table-inline',
  templateUrl: './dataSelectorTableInlineComponent.html'
})
export class DataSelectorTableInlineComponent extends AbstractDataSelectorGridComponent {

  @ViewChild(DataSelectorValueDetailPopupComponent)
  public valueDetailPopup: DataSelectorValueDetailPopupComponent;

  public tempSelectedValue: GridRow;

  public tableMinWidth: string;

  public isDataReady: boolean = false;

  public imageSet: string;

  public hasValue = false;

  constructor(
    @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(DataSelectorStore) public dataSelectorStore: DataSelectorStore,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    public globalDataStore: GlobalDataStore,
    public cdr: ChangeDetectorRef,
    public pagerService: PagerService
  ) {
    super(confMessageProvider, dataSelectorStore, productStore, confStore, storeSession, visualObjectHelper, globalDataStore, pagerService);
  }

  ngOnInit() {
    this.tableMinWidth = this.decoration.dialogWidth + "px";

    this.imageSet = this.getImageSet(ImageSets.ConfiguratorDecorationHeader);

    super.ngOnInit();
  }

  public clearValue() {
    this.dataSelectorStore.clearValue(this.storeSession.activeConfigurationId, this.storeSession.confSessionId, this.decoration.visualObjectId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['confValue']) {
      let gridData = this.confValue.gridData;
      this.setGridData(gridData);
      this.isDataReady = true;
      this.hasValue = this.confValue.value != null;
    }
  }

  public onValueClick(value: DataRowView) {
    //super.onValueClick(value); // Don't call super, we don't want to set value untill it's confirmed.
    let id = parseInt(value.id);
    this.tempSelectedValue = this.gridRows.find(x => x.id === id);

    // Open detail popup to confirm
    this.valueDetailPopup.show(this.decoration, this.tempSelectedValue);
  }

  public onOkClick() {
    // Now we can confirm value
    this.selectedValue = this.tempSelectedValue;
    this.setSelectedValue();
  }

  public handleSetSelectedValueError() {
    // TODO we should use a popup dialog to display the error.
  }
  public handleSetSelectedValueSuccess() {
  }
}