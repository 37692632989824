import { Registration } from "../shared/ioc/registration"
import { AppComponent } from "./appComponent";
import { ConfiguratorComponent } from "../pages/configurator/configuratorComponent";
import { SummaryViewComponent } from "../pages/configurator/summaryViewComponent";
import { EditorViewComponent } from "../pages/configurator/editorViewComponent";
import { ConfViewRedirectorComponent } from "../pages/configurator/confViewRedirectorComponent";
import { VisualObjectsContainerComponent } from "../pages/configurator/shared/visualObjectsContainerComponent";
import { VisualObjectFactoryComponent } from "../pages/configurator/shared/visualObjectFactoryComponent";
import { DetailComponent } from "../pages/selector/detailComponent";
import { SelectorComponent } from "../pages/selector/selectorComponent";
import { SelectorHeaderComponent } from "../pages/selector/selectorHeaderComponent";
import { StartComponent } from "../pages/start/startComponent";
import { SettingsComponent } from "../pages/settings/settingsComponent";
import { RedirectorComponent } from "../pages/redirector/redirectorComponent";
import { DoubleParamComponent } from "../pages/configurator/parameters/double/doubleParamComponent";
import { DataSelectorComponent, DataSelectorParamDetailComponent, DataSelectorTableInlineComponent, DataSelectorTableModalComponent, DataSelectorValueDetailComponent, DataSelectorValueDetailPopupComponent } from "../pages/configurator/decorations/dataSelector";
import { DocumentsDecorationComponent } from "../pages/configurator/decorations/documents/documentsDecorationComponent";
import { DocumentsLinkComponent } from "../pages/configurator/shared/documentsLinkComponent";
import { AttributeDecorationComponent } from "../pages/configurator/decorations/attribute/attributeDecorationComponent";
import { HeaderDecorationComponent } from "../pages/configurator/decorations/header/headerDecorationComponent";
import { PriceListDecorationComponent } from "../pages/configurator/decorations/priceList/priceListDecorationComponent";
import { GraphicsDecorationComponent } from "../pages/configurator/decorations/graphics/graphicsDecorationComponent";
import { TextDecorationComponent } from "../pages/configurator/decorations/text/textDecorationComponent";
import { ImageDecorationComponent } from "../pages/configurator/decorations/image/imageDecorationComponent";
import { BomDecorationComponent, BomCreateControlComponent, BomEditControlComponent, BomDeletePopupComponent, BomDecorationContentsComponent } from "../pages/configurator/decorations/bom";
import { BoolParamComponent } from "../pages/configurator/parameters/bool/boolParamComponent";
import { LookupParamComponent } from "../pages/configurator/parameters/lookup/lookupParamComponent";
import { IntParamComponent } from "../pages/configurator/parameters/int/intParamComponent";
import { MultiChoiceParamComponent } from "../pages/configurator/parameters/multiChoice/multiChoiceParamComponent";
import { MultiChoiceValueComponent } from "../pages/configurator/parameters/multiChoice/multiChoiceValueComponent";
import { StringParamComponent } from "../pages/configurator/parameters/string/stringParamComponent";
import { CompositeComponent } from "../pages/configurator/actions/composite/compositeComponent";
import { CompositeMenuItemComponent } from "../pages/configurator/actions/composite/compositeMenuItemComponent";
import { CompositeMenuControllerComponent } from "../pages/configurator/actions/composite/compositeMenuControllerComponent";
import { CompositeMenuItemDetailFactory } from "../pages/configurator/actions/composite/compositeMenuItemDetailComponent";
import { ControlPanelComponent } from "../pages/configurator/actions/controlPanel/controlPanelComponent";
import { ImageComponent } from "../shared/components/image/imageComponent";
import { TreeNodeComponent } from "../shared/components/treeView/treeNodeComponent";
import { TreeViewComponent } from "../shared/components/treeView/treeViewComponent";
import { ExpandCollapseComponent } from "../shared/components/treeView/expandCollapseComponent";
import { MenuTreeViewComponent } from "../pages/configurator/actions/composite/tree/menuTreeViewComponent";
import { DeleteConfigurationComponent } from "../pages/configurator/actions/composite/delete/deleteConfigurationComponent";
import { CopyConfigurationComponent } from "../pages/configurator/actions/composite/copy/copyConfigurationComponent";
import { ImportConfigurationComponent } from "../pages/configurator/actions/composite/import/importConfigurationComponent";
import { AddConfigurationComponent } from "../pages/configurator/actions/composite/add/addConfigurationComponent";
import { CompositeHeaderComponent } from "../pages/configurator/actions/composite/compositeHeaderComponent";
import { FilterComponent } from "../pages/configurator/components/filter/filterComponent";
import { ImmutableValuePopupComponent } from "../pages/configurator/popups/shared/immutableValuePopupComponent";
import { DisallowedPopupComponent } from "../pages/configurator/popups/disallowed/disallowedPopupComponent";
import { ReadOnlyPopupComponent } from "../pages/configurator/popups/readonly/readOnlyPopupComponent";
import { HelpPopupComponent } from "../pages/configurator/popups/help/helpPopupComponent";
import { MandatoryPopupComponent } from "../pages/configurator/popups/mandatory/mandatoryPopupComponent";
import { WarningAttributesPopupComponent } from "../pages/configurator/popups/warningAttributes/warningAttributesPopupComponent";
import { RuleInformationPopupComponent } from "../pages/configurator/popups/rule/ruleInformationPopupComponent";
import { RuleConfirmationPopupComponent } from "../pages/configurator/popups/rule/ruleConfirmationPopupComponent";
import { PriceSettingPopupComponent } from "../pages/configurator/popups/price/priceSettingPopupComponent";
import { PriceListReportPopupComponent } from "../pages/configurator/popups/price/priceListReportPopupComponent";
import { ProductButtonComponent } from "../pages/configurator/actions/composite/productButtonComponent";
import { ProductButtonsContainerComponent } from "../pages/configurator/actions/composite/productButtonsContainerComponent";
import { IsNullOrWhiteSpacePipe } from "../shared/utils/pipes";
import { LoginComponent, LogoutComponent, AnonymousAccessEntryComponent, AccountSubRouteComponent, AccountComponent, PasswordChangeComponent, CreateProfileComponent, SignUpConfirmEmailComponent, AccountVerificationComponent, EmailFormComponent, VerifyFormComponent } from "../pages/account";
import { TabsSidebarComponent } from "../pages/configurator/components/tabs/tabsSidebarComponent";
import { TabsManagerComponent, NormalTabsContainerComponent, SeparatedTabComponent, FloatedSeparatedTabsComponent, AccordionTabsComponent, AccordionConfigurationsTreeComponent } from "../pages/configurator/components/tabs";
import { GetStartedComponent, NewsComponent, QuickSearchComponent, RecentsComponent, ConfigurationLinkControlComponent, ConfigurationContextMenuComponent, SearchNavigatorComponent, InlineSearchResultComponent } from "../pages/start/components";
import { VisualObjectGroupComponent } from "../pages/configurator/shared/visualObjectGroupComponent";
import { ClearedPopupComponent } from "../pages/configurator/popups/cleared/clearedPopupComponent";
import { ConfigurationLinkComponent } from "../pages/configurator/shared/configurationLinkComponent";
import { VisualObjectLinkComponent } from "../pages/configurator/shared/visualObjectLinkComponent";
import { SortConfigurationsComponent } from "../pages/configurator/actions/composite/sort/sortConfigurationsComponent";
import { BomTableComponent } from "../pages/configurator/shared/bom/bomTableComponent";
import { BomGrandTotalComponent } from "../pages/configurator/shared/bom/bomGrandTotalComponent";
import { SummaryActionsBoxesComponent, SummaryDetailComponent, ConfPropertiesDetailComponent, TabDetailComponent, AttributesDetailComponent, BomDetailComponent, DocumentsDetailComponent, GraphicsDetailComponent, PricesDetailComponent, SummaryParamDetailComponent, CodeDetailComponent, ImageDetailComponent, VisualizationDetailComponent } from "../pages/configurator/summary";
import { StatesActionBoxComponent, StateItemComponent, StateHistoryPopupComponent, StateActionPopupComponent } from "../pages/shared/components/states";
import { GraphicsDownloadComponent, GraphicsRendererComponent, GraphicsActionBoxComponent } from "../pages/shared/components/graphics";
import { HeaderComponent } from "../pages/shared/components/header/headerComponent";
import { GraphicsPopupComponent, BomCompositePopupComponent, BomImagePopupComponent, ConfigurationSearchPopupComponent, AnonymousDialogPopupComponent, CodePopupComponent, AttributePopperComponent, AttributePopperDirective } from "../pages/configurator/popups";
import { SearchComponent } from "../pages/configurator/search/searchComponent";
import { SearchResultComponent } from "../pages/configurator/search/result/searchResultComponent";
import { SearchFilterComponent } from "../pages/configurator/search/filter/searchFilterComponent";
import { SearchCalendarInputComponent, SearchCriteriaComponent, SearchDropDownInputComponent, SearchTextInputComponent, MultiSelectInputComponent, SearchRangeTextboxComponent, SearchCheckboxInputComponent } from "../pages/configurator/search/filter/criteria";
import { UserSettingsComponent, UserProfileComponent } from "../pages/settings";
import { RuleParamsInfoComponent } from "../pages/configurator/popups/shared/ruleParamsInfoComponent";
import { InlineSpinnerComponent } from "../shared/components/spinner/inlineSpinnerComponent";
import { SearchInfoComponent } from "../pages/configurator/search/searchInfoComponent";
import { ChangeOwnershipPopupComponent, OperationsActionBoxComponent } from "../pages/shared/components/operations";
import { ReportsActionBoxComponent, RevisionItemComponent, RevisionsActionBoxComponent, AttachmentsActionBoxComponent, AttachmentsUploadPopupComponent, ChangePasswordPopupComponent, DeleteAttachmentPopupComponent, UploadComponent, DocumentsActionBoxComponent } from "../pages/shared/components";
import { BrowsersComponent } from "./browsersComponent";
import { SummaryPrintComponent } from "../pages/shared/components/print/summaryPrintComponent";
import { PrintCheckboxComponent } from "../pages/shared/components/print/printCheckboxComponent";
import { RecoverConfirmEmailComponent } from "../pages/account/password";
import { SvgPanZoomDirective, GraphicsControlIconsComponent } from "../pages/shared/components/graphics";
import { PriceListReportComponent } from "../pages/shared/components/priceReport/priceListReportComponent";
import { CustomLinkComponent } from "../shared/components/customLink/customLinkComponent";
import { CodeDecorationComponent } from "../pages/configurator/decorations/code/codeDecorationComponent";
import { CodeActionBoxComponent, CodeRenderStyleComponent, CodeDownloadLinkStyleComponent, DecorationCodesRendererComponent, CodePopupStyleComponent, DecorationCodesBuilderComponent } from "../pages/shared/components/code";
import { VisualObjectGroupDirective } from "../pages/configurator/shared/visualObjectGroupDirective";
import { ComparisonComponent, ComparisonRowComponent, ComparisonTreeComponent, ComparisonDraggableItemComponent, ComparisonValueComponent } from "../pages/configurator/comparison";
import { StartWelcomeComponent } from "../pages/start/components/startWelcomeComponent";
import { DataSelectorDetailComponent } from "../pages/configurator/summary/dataSelector/dataSelectorDetailComponent";
import { PriceEditorComponent } from "../pages/shared/components/priceReport/components/priceEditorComponent";
import { SystemInformationComponent } from "../pages/start/components/systemInformationComponent";
import { WarningPricePopupComponent } from "../pages/configurator/popups/priceWarning/warningPricePopupComponent";
import { CrmIntegrationPopupComponent } from
  "../pages/configurator/popups/msCrmIntegrationInteraction/crmIntegrationPopupComponent";
import { VisualizationDecorationComponent } from "../pages/configurator/decorations/visualization/visualizationDecorationComponent";
import { TabCompositeConfigurationsComponent } from "../pages/configurator/shared/tabCompositeConfigurationsComponent";
import { VisualizationPluginComponent } from "../pages/configurator/decorations/visualization/visualizationPluginComponent";
import { FormDecorationComponent } from "../pages/configurator/decorations/form/formDecorationComponent";
import { NestedAccordionTabsComponent } from "../pages/configurator/components/tabs/nestedAccordionTabsComponent";

import { DealerControlPanelComponent } from "../pages/dealerControlPanel/dealerControlPanelComponent";
import { DealerHelpPopupComponent } from "../pages/dealerControlPanel/dealerHelpPopupComponent";
import { TermsMessageBoxComponent } from "../pages/shared/components/header/termsMessageBoxComponent";

export let declarationList: any[] = [
  AppComponent,
  ConfiguratorComponent,
  SummaryViewComponent,
  EditorViewComponent,
  ConfViewRedirectorComponent,
  VisualObjectsContainerComponent,
  DetailComponent,
  SelectorComponent,
  SelectorHeaderComponent,
  StartComponent,
  SearchComponent,
  SearchResultComponent,
  SettingsComponent,
  RedirectorComponent,
  DataSelectorComponent,
  DataSelectorTableInlineComponent,
  DataSelectorTableModalComponent,
  DocumentsDecorationComponent,
  DocumentsLinkComponent,
  BoolParamComponent,
  DoubleParamComponent,
  IntParamComponent,
  LookupParamComponent,
  MultiChoiceParamComponent,
  MultiChoiceValueComponent,
  StringParamComponent,
  TreeNodeComponent,
  TreeViewComponent,
  HeaderComponent,
  MenuTreeViewComponent,
  AttributeDecorationComponent,
  PriceListDecorationComponent,
  GraphicsDecorationComponent,
  CodeDecorationComponent,
  TextDecorationComponent,
  HeaderDecorationComponent,
  ImageDecorationComponent,
  BomDecorationComponent,
  BomDecorationContentsComponent,
  BomCreateControlComponent,
  BomEditControlComponent,
  BomDeletePopupComponent,
  BomTableComponent,
  BomGrandTotalComponent,
  CompositeComponent,
  CompositeMenuControllerComponent,
  FilterComponent,
  ImmutableValuePopupComponent,
  DisallowedPopupComponent,
  ReadOnlyPopupComponent,
  HelpPopupComponent,
  MandatoryPopupComponent,
  WarningAttributesPopupComponent,
  RuleInformationPopupComponent,
  RuleConfirmationPopupComponent,
  PriceListReportPopupComponent,
  BomCompositePopupComponent,
  BomImagePopupComponent,
  AttributePopperComponent,
  AttributePopperDirective,
  GraphicsPopupComponent,
  CodePopupComponent,
  PriceSettingPopupComponent,
  VisualObjectFactoryComponent,
  IsNullOrWhiteSpacePipe,
  LoginComponent,
  LogoutComponent,
  AddConfigurationComponent,
  DeleteConfigurationComponent,
  ImportConfigurationComponent,
  CopyConfigurationComponent,
  CompositeMenuItemDetailFactory,
  ControlPanelComponent,
  CompositeHeaderComponent,
  CompositeMenuItemComponent,
  ProductButtonsContainerComponent,
  ProductButtonComponent,
  TabsSidebarComponent,
  TabsManagerComponent,
  SeparatedTabComponent,
  NormalTabsContainerComponent,
  FloatedSeparatedTabsComponent,
  VisualObjectGroupComponent,
  ClearedPopupComponent,
  ConfigurationLinkComponent,
  VisualObjectLinkComponent,
  ConfigurationLinkComponent,
  GetStartedComponent,
  NewsComponent,
  QuickSearchComponent,
  RecentsComponent,
  ConfigurationLinkControlComponent,
  SortConfigurationsComponent,
  SummaryActionsBoxesComponent,
  SummaryDetailComponent,
  OperationsActionBoxComponent,
  ReportsActionBoxComponent,
  DocumentsActionBoxComponent,
  RevisionsActionBoxComponent,
  RevisionItemComponent,
  StatesActionBoxComponent,
  StateItemComponent,
  StateHistoryPopupComponent,
  StateActionPopupComponent,
  AttachmentsActionBoxComponent,
  AttachmentsUploadPopupComponent,
  ConfigurationContextMenuComponent,
  ConfPropertiesDetailComponent,
  TabDetailComponent,
  SummaryParamDetailComponent,
  AttributesDetailComponent,
  BomDetailComponent,
  DocumentsDetailComponent,
  GraphicsDetailComponent,
  GraphicsDownloadComponent,
  GraphicsRendererComponent,
  GraphicsActionBoxComponent,
  PricesDetailComponent,
  CodeDetailComponent,
  VisualizationDetailComponent,
  CodeActionBoxComponent,
  DecorationCodesRendererComponent,
  SearchCriteriaComponent,
  SearchFilterComponent,
  SearchCalendarInputComponent,
  SearchDropDownInputComponent,
  SearchTextInputComponent,
  SearchCheckboxInputComponent,
  SearchRangeTextboxComponent,
  ChangePasswordPopupComponent,
  UserSettingsComponent,
  ConfigurationSearchPopupComponent,
  UserProfileComponent,
  RuleParamsInfoComponent,
  SearchNavigatorComponent,
  InlineSearchResultComponent,
  InlineSpinnerComponent,
  SearchInfoComponent,
  ChangeOwnershipPopupComponent,
  ExpandCollapseComponent,
  AnonymousDialogPopupComponent,
  AnonymousAccessEntryComponent,
  AccountSubRouteComponent,
  DeleteAttachmentPopupComponent,
  UploadComponent,
  BrowsersComponent,
  SummaryPrintComponent,
  PrintCheckboxComponent,
  AccountVerificationComponent,
  VerifyFormComponent,
  PasswordChangeComponent,
  AccountComponent,
  CreateProfileComponent,
  SignUpConfirmEmailComponent,
  RecoverConfirmEmailComponent,
  EmailFormComponent,
  GraphicsControlIconsComponent,
  SvgPanZoomDirective,
  AccordionTabsComponent,
  PriceListReportComponent,
  CustomLinkComponent,
  MultiSelectInputComponent,
  ComparisonComponent,
  ComparisonRowComponent,
  ComparisonTreeComponent,
  ComparisonDraggableItemComponent,
  ComparisonValueComponent,
  VisualObjectGroupDirective,
  StartWelcomeComponent,
  CodeRenderStyleComponent,
  CodePopupStyleComponent,
  CodeDownloadLinkStyleComponent,
  DecorationCodesBuilderComponent,
  DataSelectorDetailComponent,
  PriceEditorComponent,
  SystemInformationComponent,
  WarningPricePopupComponent,
  VisualizationDecorationComponent,
  FormDecorationComponent,
  VisualizationPluginComponent,
  ImageDetailComponent,
  TabCompositeConfigurationsComponent,
  AccordionConfigurationsTreeComponent,
  NestedAccordionTabsComponent,
  //MsCRM
  CrmIntegrationPopupComponent,
  DataSelectorValueDetailComponent,
  DataSelectorValueDetailPopupComponent,
  DataSelectorParamDetailComponent

  , DealerControlPanelComponent
  , DealerHelpPopupComponent
  , TermsMessageBoxComponent
]