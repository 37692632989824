import { BaseEntity } from "../../../../pages/shared/baseEntity"

export abstract class CusPriceFactor extends BaseEntity {
  protected readonly FACTOR = "factor";
  protected readonly IDENTITY = "identity";
  protected readonly PREFIX = "prefix";
  protected readonly SUFFIX = "suffix";
  protected readonly WORKGROUP_ID = "workgroupId";
  protected readonly PRICE_CATEGORY_ID = "priceCategoryId";
  protected readonly SERIES = "series";
  protected readonly MAKRUP_TYPE = "markupType";
  protected readonly REATIL_MAKRUP = "retailMarkup";
  protected readonly WHOLESALER_MARKUP = "wholesalerMarkup";
  protected readonly PRODUCT_ID = "productId";

  get factor(): number { return this.getInternalValue<number>(this.FACTOR); }
  setFactor(factor: number): this { return this.setInternalValue(this.FACTOR, factor); }

  get identity(): string { return this.getInternalValue<string>(this.IDENTITY); }
  setIdentity(identity: string): this { return this.setInternalValue(this.IDENTITY, identity); }

  get prefix(): string { return this.getInternalValue<string>(this.PREFIX); }
  setPrefix(prefix: string): this { return this.setInternalValue(this.PREFIX, prefix); }

  get suffix(): string { return this.getInternalValue<string>(this.SUFFIX); }
  setSuffix(suffix: string): this { return this.setInternalValue(this.SUFFIX, suffix); }

  get workgroupId(): number { return this.getInternalValue<number>(this.WORKGROUP_ID); }
  setWorkgroupId(workgroupId: number): this { return this.setInternalValue(this.WORKGROUP_ID, workgroupId); }

  get productId(): number { return this.getInternalValue<number>(this.PRODUCT_ID); }
  setProductId(productId: number): this { return this.setInternalValue(this.PRODUCT_ID, productId); }

  get priceCategoryId(): number { return this.getInternalValue<number>(this.PRICE_CATEGORY_ID); }
  setPriceCategoryId(priceCategoryId: number): this { return this.setInternalValue(this.PRICE_CATEGORY_ID, priceCategoryId); }

  get series(): string { return this.getInternalValue<string>(this.SERIES); }
  setSeries(series: string): this { return this.setInternalValue(this.SERIES, series); }

  get markupType(): number { return this.getInternalValue<number>(this.MAKRUP_TYPE); }
  setMarkupType(markupType: number): this { return this.setInternalValue(this.MAKRUP_TYPE, markupType); }

  get retailMarkup(): number { return this.getInternalValue<number>(this.REATIL_MAKRUP); }
  setRetailMarkup(retailMarkup: number): this { return this.setInternalValue(this.REATIL_MAKRUP, retailMarkup); }

  get wholesalerMarkup(): number { return this.getInternalValue<number>(this.WHOLESALER_MARKUP); }
  setWholesalerMarkup(wholesalerMarkup: number): this { return this.setInternalValue(this.WHOLESALER_MARKUP, wholesalerMarkup); }
}