import { Inject, Component, Input, Optional, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { PopupService } from "../../../../shared/components";
import { LocalStorageService } from "../../providers";
import { LocalStorageData } from "../../models";
import { AccountDataStore } from "../../providers/accountData";
import { HttpService, GlobalServiceLocator } from "../../../../shared/providers";

import { GlobalDataStore, Strings } from '../../providers/globalData';
import { DoosanGlobalSettings } from "../../../../pages/shared/models/responses/doosanGlobalSettings";
import { ManagedSubscription } from "../../../../shared/managedSubscription";

@Component({
  selector: 'terms-message-box',
  templateUrl: './termsMessageBoxComponent.html'
})
export class TermsMessageBoxComponent {

  public localStorageChangeSubscription: ManagedSubscription;

  public visible: boolean = true;

  public id = "terms-message-box";

  public height: string = "";

  public width: string = "800px";

  public icon: string = "info";

  public title: string = "";
  public description: string = "";
  public message: string = "";

  constructor(
    public cdr: ChangeDetectorRef,
    public localStorageService: LocalStorageService,
    public accountDataStore: AccountDataStore,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(Strings) public strings: Strings,
    @Inject(HttpService) public httpService: HttpService,
    @Optional() public popupService?: PopupService
  ) {

  }

  ngOnInit() {
    if (this.globalDataStore.getGlobalData().globalSettings.propertyMap.get("displayTermsOfUsePopup"))
      this.localStorageChangeSubscription = this.localStorageService.onLocalStorageChanged((localStorageData: LocalStorageData) => {
        if (localStorageData.username) {
          let usr = this.accountDataStore.getUser();

          if (usr && !usr.propertyMap.get("hasAgreedToTerms")) {
            this.show();
          }
        }
      });
  }

  public ngOnDestroy() {
    if (this.localStorageChangeSubscription)
      this.localStorageChangeSubscription.unsubscribe();
  }

  public open(tag: any) {
    this.show();
  }

  public show(): void {

    this.title = this.strings.getString('termsofuse');
    this.description = this.strings.getString('readandaccepttocontinue');
    this.message = this.strings.getString('termsofusetext');

    this.visible = true;

    setTimeout(() => {
      this.popupService.open(this.id);

    }, 100);
  }

  public onOkClick($event): void {

    this.httpService.get('terms', null).subscribe((apiResponse) => {
      this.accountDataStore.updateUserTOS();
    });

    this.visible = false;

  }

  public onCancelClick($event): void {
    this.accountDataStore.endUserSession();
    this.accountDataStore.logOutUser();

    sessionStorage.removeItem("cookieConsent");

    this.visible = false;

  }

}