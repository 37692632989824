import { Injectable, Inject } from '@angular/core';
import * as Immutable from "immutable";

import { AppStore, StoreResponse, AccountDataState, BaseStore, AccountDataActions } from "../../state";
import {
  User, AccountDataRequest, LoginCommand, LogoutCommand, Customer, CustomerSite, CustomerContact,
  CurrentUserCommand, PasswordChangeCommand, AccountEntitiesCommand, WorkGroup, PasswordRecoverCommand, AccountVerificationCommand, SignUpCommand, KeyValue
} from "../../models";
import { AccountDataActionCreator } from "./accountDataActionCreator";
import { AppStoreSubscriptionManager } from "../appStoreSubscriptionManager";
import { ManagedSubject } from "../../../../shared/managedSubject";
import { ModelFactory } from "../modelFactory";
import { LocalStorageService } from "../localStorageService";
import { BaseEntity } from "../../baseEntity";
import { ValueSubscriptionManager } from "../valueSubscriptionManager";
import { LoginUserType } from '../../models/requests/commands/loginUserType';
import { SignalRService } from '../pushMessage/signalRService';
import { AccountDataController } from './accountDataController';

@Injectable()
export class AccountDataStore extends BaseStore {

  constructor(@Inject(AppStore) protected appStore: AppStore,
    protected accountDataController: AccountDataController,
    protected accountDataActionCreator: AccountDataActionCreator,
    protected appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ModelFactory) protected modelFactory: ModelFactory,
    protected localStorageService: LocalStorageService,
    protected valueSubscriptionManager: ValueSubscriptionManager,
    @Inject(SignalRService) public signalRService: SignalRService
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory);
  }

  protected getAccountData(): AccountDataState {
    return this.appStore.getState().accountData;
  }

  public createRequest(rawObject?: Partial<AccountDataRequest>): AccountDataRequest {
    return this.modelFactory.createRequestOrCommand<AccountDataRequest>(AccountDataRequest.name, rawObject);
  }

  public getUser(): User {
    return this.getAccountData().user;
  }

  public showRecaptcha(): boolean {
    return this.getAccountData().showRecaptcha;
  }

  public isAccountData(className: string) {
    let classArray = [WorkGroup.name, User.name, Customer.name, CustomerSite.name, CustomerContact.name];
    return classArray.indexOf(className) > -1;
  }

  public getEntity<T extends BaseEntity>(id: number) {
    return this.getAccountData().entities.get(+id) as T;
  }

  public getEntities<T extends BaseEntity>(className: string, forceReload?: boolean): ManagedSubject<StoreResponse<Immutable.OrderedMap<number, T>>> {
    let response = new StoreResponse<Immutable.Map<number, T>>();

    if (!forceReload && this.getAccountData().entityIdsByClassName && this.getAccountData().entityIdsByClassName.has(className)) {
      response.data = this.getEntitiesByClassNameInternal(className);
      return this.valueSubscriptionManager.createSubject(response, true);
    }

    let model = this.createRequest();
    model.entities = this.modelFactory.createRequestOrCommand<AccountEntitiesCommand>(AccountEntitiesCommand.name, { classNames: [className] });
    let action = this.accountDataActionCreator.dispatchFetchEntities(model);

    // Subscribe to store and return the results on request complete.
    return this.createAction(action, () => {
      response.data = this.getEntitiesByClassNameInternal(className);
      return response;
    });
  }

  protected getEntitiesByClassNameInternal<T extends BaseEntity>(className: string) {

    let ids = this.getAccountData().entityIdsByClassName.get(className);
    let orderedMap = Immutable.OrderedMap<number, T>();

    // ids can be null, return empty array if no data found.
    if (!ids)
      return orderedMap;
    
    ids.forEach((value, key) => {
      orderedMap = orderedMap.set(value, this.getAccountData().entities.get(value) as T);
    })

    return orderedMap;
  }

  public isUserLoggedIn() {
    return this.getAccountData().user && this.getAccountData().user.username == this.localStorageService.getData().username;
  }

  public hasUserSession() {
    return !!this.localStorageService.getData().username;
  }

  public startUserSession() {

    // If the same user is already logged in then return
    if (this.isUserLoggedIn())
      return;

    let localStorageData = this.localStorageService.getData();
    localStorageData = localStorageData.setUsername(this.getAccountData().user.username);

    this.localStorageService.setData(localStorageData);
  }

  public endUserSession() {

    // If there exists a user session then clear it
    if (this.hasUserSession())
      this.localStorageService.setData(null);

    this.signalRService.stopConnection();
  }

  public logInUser(username: string, password: string, recaptcha = "") {
    let model = this.createRequest();
    model.login = this.modelFactory.createRequestOrCommand<LoginCommand>(LoginCommand.name, { username: username, password: password, recaptcha: recaptcha });

    this.accountDataActionCreator.dispatchUserLogin(model);
  }

    public logInAnonymous(languageTitle: string = null, languageCode: string = null) {
    let model = this.createRequest();
    model.login = this.modelFactory.createRequestOrCommand<LoginCommand>(LoginCommand.name, { userType: LoginUserType[LoginUserType.Anonymous], languageTitle: languageTitle, languageCode: languageCode });

    this.accountDataActionCreator.dispatchUserLogin(model);
  }

  public logOutUser() {
    let model = this.createRequest();
    model.logout = this.modelFactory.createRequestOrCommand<LogoutCommand>(LogoutCommand.name);

    this.accountDataActionCreator.dispatchUserLogout(model);
  }

  public initAccountVerifyRequest(userNameOrEmail: string, newAccount: boolean = true): void {

    let model = this.createRequest();
    model.verify = this.modelFactory.createRequestOrCommand<AccountVerificationCommand>(AccountVerificationCommand.name, { initRequest: true, newAccountRequest: newAccount, accountRecoverRequest: !newAccount, usernameOrEmail: userNameOrEmail });

    this.accountDataActionCreator.dispatchAccountVerifyRequest(model, AccountDataActions.ACCOUNT_VERIFY_REQEUEST, AccountDataActions.ACCOUNT_VERIFY_REQUEST_RESULT);

  }

  public accountVerifyCode(userNameOrEmail: string, code: string): void {

    let model = this.createRequest();
    model.verify = this.modelFactory.createRequestOrCommand<AccountVerificationCommand>(AccountVerificationCommand.name, { verifyRequest: true, usernameOrEmail: userNameOrEmail, code: code });

    this.accountDataActionCreator.dispatchAccountVerifyRequest(model, AccountDataActions.ACCOUNT_VERIFY_CODE, AccountDataActions.ACCOUNT_VERIFY_CODE_RESULT);

  }

  public createAccount(email: string, name: string, username: string, company: string, phone: string, mob: string, password: string): void {

    let model = this.createRequest();
    model.create = this.modelFactory.createRequestOrCommand<SignUpCommand>(SignUpCommand.name,
      {
        username: username,
        email: email,
        name: name,
        company: company,
        phoneNumber: phone,
        mobileNumber: mob,
        password: password

      });

    this.accountDataActionCreator.dispatchCreateAccount(model);

  }

  public recoverPassword(userNameOrEmail: string, newPassword: string): void {

    let model = this.createRequest();
    model.passwordRecover = this.modelFactory.createRequestOrCommand<PasswordRecoverCommand>(PasswordRecoverCommand.name, { changePasswordRequest: true, username: userNameOrEmail, newPassword: newPassword });

    this.accountDataActionCreator.dispatchVerifyCode(model);
  }

  validateSession(userNameOrEmail: string): void {

    let model = this.createRequest();
    model.passwordRecover = this.modelFactory.createRequestOrCommand<PasswordRecoverCommand>(PasswordRecoverCommand.name, { username: userNameOrEmail, ValidateSessionRequest: true });

    this.accountDataActionCreator.dispatchVerifyCode(model);

  }

  public hasLoggedInUser(): ManagedSubject<boolean> {
    return new ManagedSubject<boolean>(this.accountDataController.hasLoggedInUser(), true, 1, false);
  }

  public getLoggedInUser(): ManagedSubject<StoreResponse<User>> {
    //let model = this.createRequest();
    //model.currentUser = this.modelFactory.createRequestOrCommand<CurrentUserCommand>(CurrentUserCommand.name, { get: true });

    //let action = this.accountDataActionCreator.dispatchRequestLoggedInUser(model);
    let action = this.accountDataActionCreator.dispatchRequestLoggedInUser();

    // Create request object
    let response: StoreResponse<User> = new StoreResponse<User>();

    // Subscribe to store and return the results on request complete.
    return this.createAction(action, () => {
      response.data = this.getAccountData().user;

      // If user is available the start browser user session otherwise end it
      if (response.data)
        this.startUserSession();
      else
        this.endUserSession();

      return response;
    });
  }

  public updateProfile(name: string, email: string, company: string, phoneNumber: string, mobileNumber: string,) {
    let model = this.createRequest();
    model.currentUser = this.modelFactory.createRequestOrCommand<CurrentUserCommand>(CurrentUserCommand.name);
    model.currentUser.updateUser = [
      { key: User.NAME, value: name },
      { key: User.EMAIL, value: email },
      { key: User.COMPANY, value: company },
      { key: User.PHONE_NUMBER, value: phoneNumber },
      { key: User.MOBILE_NUMBER, value: mobileNumber }
    ];
    model.currentUser.get = true;

    this.accountDataActionCreator.dispatchUpdateProfile(model);
  }

  public changeUserPassword(username: string, currentPassword: string, newPassword: string) {
    let model = this.createRequest();
    model.passwordChange = this.modelFactory.createAny<PasswordChangeCommand>(PasswordChangeCommand.name);
    model.passwordChange.username = username;
    model.passwordChange.currentPassword = currentPassword;
    model.passwordChange.newPassword = newPassword;

    this.accountDataActionCreator.dispatchChangeUserPassword(model);
  }

  public updateUserSettings(hightlightMandatoryParams: boolean, languageId?: string, defaultPriceListCategoryId?: string) {
    let model = this.createRequest();
    model.currentUser = this.modelFactory.createRequestOrCommand<CurrentUserCommand>(CurrentUserCommand.name);

    let userValues: KeyValue<string, string>[] = [];
    if (languageId) {
      userValues.push({ key: "LanguageId", value: languageId });
    }
    if (defaultPriceListCategoryId) {
      userValues.push({ key: "DefaultPriceListCategoryId", value: defaultPriceListCategoryId });
    }
    model.currentUser.updateUser = userValues;

    model.currentUser.updateSettings = [
      { key: "HighlightMandatoryParameters", value: "" + hightlightMandatoryParams }
    ];
    model.currentUser.get = true;

    this.accountDataActionCreator.dispatchUpdateUserSettings(model);
  }

  public updateCompositeControlSticky(compositeControlSticky: boolean) {

    let model = this.createRequest();
    model.currentUser = this.modelFactory.createRequestOrCommand<CurrentUserCommand>(CurrentUserCommand.name);

    model.currentUser.updateSettings = [
      { key: "CompositeControlSticky", value: "" + compositeControlSticky }
    ];
    model.currentUser.get = true;

    this.accountDataActionCreator.dispatchUpdateUserSettings(model);
  }


  // CUSTOMISATION
  public updateUserTOS() {
    let usr = this.getUser();
    usr.propertyMap = usr.propertyMap.set("hasAgreedToTerms", "true");

    this.accountDataActionCreator.dispatchUpdateUserLocal(usr);
  }

}