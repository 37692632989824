<!--  Show “Get started” section if there are at least one document classified as help.
      If only one document is classified as help link directly to the document.
      If more than one document is classified as help show a dialog that lists the help documents.
-->

<h1 class="text-uppercase start-header">{{strings.GetStarted}}</h1>
<div class="d-flex" style="margin-left: -0.5rem;">
  <div *ngIf="showGetStartedComponent" class="px-2">
    <button class="btn btn-darker mt-2 mr-2 w-100" href="#" (click)="openHelpDocument($event)">{{strings.ReadHelpGuide}}</button>
  </div>

  <div class="px-2">
    <button class="btn btn-darker mt-2 mr-2 w-100" (click)="createQuotation()">{{strings.getString('createquotation')}}</button>
  </div>

  <div style="text-align:center" class="px-2">
    <button class="btn btn-darker mt-2 mr-2 w-100" (click)="createOrder()">{{strings.getString('createorder')}}</button>
  </div>
</div>
<div class="d-flex" style="margin-left: -0.5rem;">
  <div *ngIf="showHistoryDocument" class="px-2">
    <!--<button class="btn btn-darker mt-2 mr-2 w-100" href="#" (click)="openHistorytab()">{{strings.getString('showhistorydocument')}}</button>-->
    <a href="#" (click)="openHistorytab()">{{strings.getString('showhistorydocument')}}</a>
  </div>
</div>
