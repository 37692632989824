import * as Immutable from "immutable"

import { Assortment } from "./assortment"

export class Product extends Assortment
{
  protected readonly TABS = "tabIds";
  protected readonly ACCORDION_TABS_WIDTH = "accordionTabsWidth";
  protected readonly SHOW_CHILDREN_IN_ACCORDION_TABS = "showChildrenInAccordionTabs";

  get accordionTabsWidth(): number { return this.getInternalValue<number>(this.ACCORDION_TABS_WIDTH); }
  setAccordionTabsWidth(accordionTabsWidth: number): this { return this.setInternalValue(this.ACCORDION_TABS_WIDTH, accordionTabsWidth); }

  get tabs(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.TABS); }
  settabs(tabs: Immutable.List<number>): this { return this.setInternalValue(this.TABS, tabs); }

  get showChildrenInAccordionTabs(): boolean { return this.getInternalValue<boolean>(this.SHOW_CHILDREN_IN_ACCORDION_TABS); }
  setShowChildrenInAccordionTabs(showChildrenInAccordionTabs: boolean): this { return this.setInternalValue(this.SHOW_CHILDREN_IN_ACCORDION_TABS, showChildrenInAccordionTabs); }

  // Customization
  protected readonly CUS_HIDE_FOR_MARKETS = "cusHideForMarkets";
  get cusHideForMarkets(): string { return this.getInternalValue<string>(this.CUS_HIDE_FOR_MARKETS); }
  setCusHideForMarkets(cusHideForMarkets: string): this { return this.setInternalValue(this.CUS_HIDE_FOR_MARKETS, cusHideForMarkets); }

  protected readonly CUS_GROUPING = "cusGrouping";
  get cusGrouping(): string { return this.getInternalValue<string>(this.CUS_GROUPING); }
  setCusGrouping(cusGrouping: string): this { return this.setInternalValue(this.CUS_GROUPING, cusGrouping); }
  // End Customization

}